//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import IdleVue from 'idle-vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import NaviBar from '~/components/NaviBar'
import { SidebarMenu } from 'vue-sidebar-menu'

Vue.use(Vuex)

const eventsHub = new Vue()
const store = new Vuex.Store()

Vue.use(VueCookies)
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: parseInt(window.__NUXT__.config.sessionTime) * 60 * 1000
})

export default {
  components: {
    NaviBar,
    SidebarMenu
  },
  data() {
    return {
      timerId: null,
      sideWidth: '245px',
      collapsed: false,
      menu: [
        {
          href: '/oversee/dashboard',
          title: '대시보드',
          icon: 'dashboard'
        },
        {
          href: '/oversee/policies/policiesschedule#WeekSchedule',
          title: '근무제',
          icon: 'workingpolicies',
          alias: [
            '/oversee/policies/policiesschedule#DaySchedule',
            '/oversee/policies/policiesschedule#WeekSchedule',
            '/oversee/policies/policiesschedule#MonthSchedule'
          ],
          child: [
            {
              href: '/oversee/policies/policiesschedule',
              title: '스케줄 조회',
              alias: ['#DaySchedule', '#WeekSchedule', '#MonthSchedule']
            },
            {
              href: '/oversee/policies/policiescommon',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : '',
              title: '공통 근무제'
            },
            {
              href: '/oversee/policies/policiesteam',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : '',
              title: '부서별 근무제'
            },
            {
              href: '/oversee/policies/policiesuser',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : '',
              title: '개인별 근무제'
            },
            {
              href: '/oversee/policies/additionalwork',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : '',
              title: '추가/외부근로설정',
              alias: [
                '/oversee/policies/additionalwork#AdditionalSetting',
                '/oversee/policies/additionalwork#AdditionalAssign',
                '/oversee/policies/additionalwork#DeemedSetting'
              ]
            }
          ]
        },
        {
          href: '/oversee/workingtime/workingtime',
          title: '근로시간',
          icon: 'workingtime'
        },
        {
          href: '/oversee/workingattitude/workingattitudestatus',
          title: '근태',
          icon: 'workingattitude',
          child: [
            {
              href: '/oversee/workingattitude/workingattitudestatus',
              title: '근태현황'
            },
            {
              href: '/oversee/workingattitude/workingattitudesetting',
              title: '근태설정',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : ''
            }
          ]
        },
        {
          href: '/oversee/leave/leavestatus',
          title: '휴가/휴일',
          icon: 'leave',
          alias: [
            '/oversee/leave/usesetting#UseSettingLeave',
            '/oversee/leave/usesetting#UseSettingLabor'
          ],
          child: [
            {
              href: '/oversee/leave/leavestatus',
              title: '휴가 현황'
            },
            {
              href: '/oversee/leave/usestatus',
              title: '연차사용촉진 현황',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : ''
            },
            {
              href: '/oversee/leave/leavesetting',
              title: '휴가 설정',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : ''
            },
            {
              href: '/oversee/leave/usesetting',
              title: '연차사용촉진 설정',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : ''
            },
            {
              href: '/oversee/leave/leaveholiday',
              title: '휴일 설정',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : ''
            }
          ]
        },
        {
          href: '/oversee/pcoff/pcoffstatus',
          title: 'PC-OFF',
          class:
            this.$auth.loggedIn === true &&
            this.$auth.user.acr.admin_site.pages.pcoff.accessible === true
              ? ''
              : 'menu-hidden',
          icon: 'pcoff',
          child: [
            {
              href: '/oversee/pcoff/pcoffstatus',
              title: 'PC 사용내역'
            },
            {
              href: '/oversee/pcoff/pcoffsetting',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : '',
              title: 'PC-OFF 설정',
              alias: [
                '/oversee/pcoff/pcoffsetting#PcOffSetting',
                '/oversee/pcoff/pcoffsetting#PcOffSchedule'
              ]
            },
            {
              href: '/oversee/pcoff/pcoffexception',
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.manager &&
                this.$auth.user.manager.access_level <= 40
                  ? 'menu-hidden'
                  : '',
              title: '예외대상자'
            }
          ]
        },
        {
          href: '/oversee/approval/approval',
          title: '결재',
          class:
            this.$auth.loggedIn === true &&
            this.$auth.user.manager &&
            this.$auth.user.manager.access_level <= 40
              ? 'menu-hidden'
              : '',
          icon: 'approval',
          child: [
            {
              href: '/oversee/approval/approval',
              title: '결재현황'
            },
            {
              href: '/oversee/approval/approvalsetting',
              title: '결재문서 설정'
            }
          ]
        },
        {
          href: '/oversee/notice',
          title: '공지',
          class:
            this.$auth.loggedIn === true &&
            this.$auth.user.manager &&
            this.$auth.user.manager.access_level <= 40
              ? 'menu-hidden'
              : '',
          icon: 'notice'
        },
        {
          class:
            this.$auth.loggedIn === true &&
            this.$auth.user.manager &&
            this.$auth.user.manager.access_level <= 40
              ? ''
              : 'menu-divider'
        },
        {
          href: '/oversee/company/workplace',
          title: '회사',
          class:
            this.$auth.loggedIn === true &&
            this.$auth.user.manager &&
            this.$auth.user.manager.access_level <= 40
              ? 'menu-hidden'
              : '',
          icon: 'office',
          child: [
            {
              href: '/oversee/company/workplace',
              title: '회사'
            },
            {
              href: '/oversee/company/organization',
              title: '조직도'
            },
            {
              href: '/oversee/company/employee',
              title: '사용자'
            },
            {
              href: '/oversee/company/position',
              title: '직위/직책'
            }
          ]
        },
        {
          href: '/oversee/agent/distribution',
          title: '에이전트/앱',
          class:
            this.$auth.loggedIn === true &&
            this.$auth.user.manager &&
            this.$auth.user.manager.access_level <= 40
              ? 'menu-hidden'
              : '',
          icon: 'agent',
          alias: [
            '/oversee/agent/agentpolicies#PC',
            '/oversee/agent/agentpolicies#Mobile'
          ],
          child: [
            {
              href: '/oversee/agent/distribution',
              title: '설치상태/배포'
            },
            {
              href: '/oversee/agent/agentpolicies',
              title: '설치기기 관리',
              alias: ['#PC', '#Mobile']
            },
            {
              href: '/oversee/agent/password',
              title: '임시 비밀번호'
            }
          ]
        }
      ]
    }
  },
  computed: {
    marginContent: function() {
      return this.collapsed
        ? '73px'
        : this.$auth.loggedIn &&
          !this.mobileCheck &&
          this.$auth.user.manager &&
          /^\/oversee/.test(this.$route.path)
        ? '245px'
        : '0px'
    }
  },
  watch: {
    async isAppIdle(val) {
      val === true
        ? Vue.$cookies.set('idle', true)
        : Vue.$cookies.set('idle', false)
    }
  },
  asyncData({ $config }) {
    return $config
  },
  async beforeMount() {
    Vue.$cookies.set('idle', false)

    document.addEventListener('click', () => {
      Vue.$cookies.set('idle', false)
    })

    document.addEventListener('keydown', () => {
      Vue.$cookies.set('idle', false)
    })

    this.timerId = setInterval(() => {
      this.idleLogout()
    }, 1000)

    if (this.$store.state.loadCheck) {
      const year = Vue.moment().format('YYYY')

      await this.$store.dispatch('getCompany')
      await this.$store.dispatch('getHolidays', year) // 휴일로드

      if (
        /^\/oversee\/\b(company\/(organization|employee)|user\/(admin|setting))/i.test(
          this.$route.path
        ) === false
      ) {
        await this.$store.dispatch('getDepartments') // 부서 조직도
        await this.$store.dispatch('getEmployees') // 사용자
      }
    }

    this.$store.dispatch('getLoadCheck', true)
  },
  created() {
    this.$auth.fetchUser() // 새로고침시 사용자 정보 재로드
  },
  methods: {
    async idleLogout() {
      if (this.$auth.loggedIn && Vue.$cookies.get('idle') === 'true') {
        await this.$store.commit(
          'logoutMessage',
          '장시간 사용하지 않아 연결이 종료되었습니다.'
        )
        await this.$store.commit('logoutModal', true)
        await this.$store.dispatch('logout')
        clearInterval(this.timerId)
      }
    },
    onCollapse(collapsed) {
      this.collapsed = collapsed
      window.$('.contents-area').css('marginLeft', '250px')
    },
    onItemClick(event, item) {
      if (item.href !== undefined) {
        window.$('#navbarCollapse').removeClass('show')
      }
    }
  }
}
