import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _415eb12e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2e849e24 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _0cd2188d = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _fe9077d8 = () => interopDefault(import('../pages/sso.vue' /* webpackChunkName: "pages/sso" */))
const _da79b094 = () => interopDefault(import('../pages/find/id.vue' /* webpackChunkName: "pages/find/id" */))
const _1e44a6f6 = () => interopDefault(import('../pages/find/password.vue' /* webpackChunkName: "pages/find/password" */))
const _b0ccdff8 = () => interopDefault(import('../pages/find/reset.vue' /* webpackChunkName: "pages/find/reset" */))
const _0d72f796 = () => interopDefault(import('../pages/join/admin.vue' /* webpackChunkName: "pages/join/admin" */))
const _48b73c06 = () => interopDefault(import('../pages/oversee/alarm.vue' /* webpackChunkName: "pages/oversee/alarm" */))
const _6c6dbc49 = () => interopDefault(import('../pages/oversee/dashboard.vue' /* webpackChunkName: "pages/oversee/dashboard" */))
const _60fc721c = () => interopDefault(import('../pages/oversee/help.vue' /* webpackChunkName: "pages/oversee/help" */))
const _83a71d1a = () => interopDefault(import('../pages/oversee/notice.vue' /* webpackChunkName: "pages/oversee/notice" */))
const _b4145080 = () => interopDefault(import('../pages/oversee/agent/agentpolicies.vue' /* webpackChunkName: "pages/oversee/agent/agentpolicies" */))
const _bc265f2e = () => interopDefault(import('../pages/oversee/agent/distribution.vue' /* webpackChunkName: "pages/oversee/agent/distribution" */))
const _54db0780 = () => interopDefault(import('../pages/oversee/agent/password.vue' /* webpackChunkName: "pages/oversee/agent/password" */))
const _6686abc4 = () => interopDefault(import('../pages/oversee/approval/approval.vue' /* webpackChunkName: "pages/oversee/approval/approval" */))
const _26afb63c = () => interopDefault(import('../pages/oversee/approval/approvalsetting.vue' /* webpackChunkName: "pages/oversee/approval/approvalsetting" */))
const _6f38e6db = () => interopDefault(import('../pages/oversee/company/employee.vue' /* webpackChunkName: "pages/oversee/company/employee" */))
const _3a23d060 = () => interopDefault(import('../pages/oversee/company/organization.vue' /* webpackChunkName: "pages/oversee/company/organization" */))
const _4f763854 = () => interopDefault(import('../pages/oversee/company/position.vue' /* webpackChunkName: "pages/oversee/company/position" */))
const _0892eb8e = () => interopDefault(import('../pages/oversee/company/workplace.vue' /* webpackChunkName: "pages/oversee/company/workplace" */))
const _45875098 = () => interopDefault(import('../pages/oversee/leave/leaveholiday.vue' /* webpackChunkName: "pages/oversee/leave/leaveholiday" */))
const _229afca8 = () => interopDefault(import('../pages/oversee/leave/leavesetting.vue' /* webpackChunkName: "pages/oversee/leave/leavesetting" */))
const _464eaf74 = () => interopDefault(import('../pages/oversee/leave/leavestatus.vue' /* webpackChunkName: "pages/oversee/leave/leavestatus" */))
const _445238c8 = () => interopDefault(import('../pages/oversee/leave/usesetting.vue' /* webpackChunkName: "pages/oversee/leave/usesetting" */))
const _47a84856 = () => interopDefault(import('../pages/oversee/leave/usestatus.vue' /* webpackChunkName: "pages/oversee/leave/usestatus" */))
const _d5f0493e = () => interopDefault(import('../pages/oversee/pcoff/pcoffexception.vue' /* webpackChunkName: "pages/oversee/pcoff/pcoffexception" */))
const _5ee0313c = () => interopDefault(import('../pages/oversee/pcoff/pcoffsetting.vue' /* webpackChunkName: "pages/oversee/pcoff/pcoffsetting" */))
const _4f7cb3d0 = () => interopDefault(import('../pages/oversee/pcoff/pcoffstatus.vue' /* webpackChunkName: "pages/oversee/pcoff/pcoffstatus" */))
const _7ce4c868 = () => interopDefault(import('../pages/oversee/policies/additionalwork.vue' /* webpackChunkName: "pages/oversee/policies/additionalwork" */))
const _e4d4a462 = () => interopDefault(import('../pages/oversee/policies/policiescommon.vue' /* webpackChunkName: "pages/oversee/policies/policiescommon" */))
const _07e7450a = () => interopDefault(import('../pages/oversee/policies/policiesschedule.vue' /* webpackChunkName: "pages/oversee/policies/policiesschedule" */))
const _25feff7e = () => interopDefault(import('../pages/oversee/policies/policiesteam.vue' /* webpackChunkName: "pages/oversee/policies/policiesteam" */))
const _4092c2af = () => interopDefault(import('../pages/oversee/policies/policiesuser.vue' /* webpackChunkName: "pages/oversee/policies/policiesuser" */))
const _7fb680b4 = () => interopDefault(import('../pages/oversee/user/admin.vue' /* webpackChunkName: "pages/oversee/user/admin" */))
const _9efd9580 = () => interopDefault(import('../pages/oversee/user/profile.vue' /* webpackChunkName: "pages/oversee/user/profile" */))
const _c2bde332 = () => interopDefault(import('../pages/oversee/user/setting.vue' /* webpackChunkName: "pages/oversee/user/setting" */))
const _3509dee4 = () => interopDefault(import('../pages/oversee/workingattitude/workingattitudesetting.vue' /* webpackChunkName: "pages/oversee/workingattitude/workingattitudesetting" */))
const _7c04c40e = () => interopDefault(import('../pages/oversee/workingattitude/workingattitudestatus.vue' /* webpackChunkName: "pages/oversee/workingattitude/workingattitudestatus" */))
const _0ff290e2 = () => interopDefault(import('../pages/oversee/workingtime/workingtime.vue' /* webpackChunkName: "pages/oversee/workingtime/workingtime" */))
const _77b70e17 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _415eb12e,
    name: "login"
  }, {
    path: "/register",
    component: _2e849e24,
    name: "register"
  }, {
    path: "/registered",
    component: _0cd2188d,
    name: "registered"
  }, {
    path: "/sso",
    component: _fe9077d8,
    name: "sso"
  }, {
    path: "/find/id",
    component: _da79b094,
    name: "find-id"
  }, {
    path: "/find/password",
    component: _1e44a6f6,
    name: "find-password"
  }, {
    path: "/find/reset",
    component: _b0ccdff8,
    name: "find-reset"
  }, {
    path: "/join/admin",
    component: _0d72f796,
    name: "join-admin"
  }, {
    path: "/oversee/alarm",
    component: _48b73c06,
    name: "oversee-alarm"
  }, {
    path: "/oversee/dashboard",
    component: _6c6dbc49,
    name: "oversee-dashboard"
  }, {
    path: "/oversee/help",
    component: _60fc721c,
    name: "oversee-help"
  }, {
    path: "/oversee/notice",
    component: _83a71d1a,
    name: "oversee-notice"
  }, {
    path: "/oversee/agent/agentpolicies",
    component: _b4145080,
    name: "oversee-agent-agentpolicies"
  }, {
    path: "/oversee/agent/distribution",
    component: _bc265f2e,
    name: "oversee-agent-distribution"
  }, {
    path: "/oversee/agent/password",
    component: _54db0780,
    name: "oversee-agent-password"
  }, {
    path: "/oversee/approval/approval",
    component: _6686abc4,
    name: "oversee-approval-approval"
  }, {
    path: "/oversee/approval/approvalsetting",
    component: _26afb63c,
    name: "oversee-approval-approvalsetting"
  }, {
    path: "/oversee/company/employee",
    component: _6f38e6db,
    name: "oversee-company-employee"
  }, {
    path: "/oversee/company/organization",
    component: _3a23d060,
    name: "oversee-company-organization"
  }, {
    path: "/oversee/company/position",
    component: _4f763854,
    name: "oversee-company-position"
  }, {
    path: "/oversee/company/workplace",
    component: _0892eb8e,
    name: "oversee-company-workplace"
  }, {
    path: "/oversee/leave/leaveholiday",
    component: _45875098,
    name: "oversee-leave-leaveholiday"
  }, {
    path: "/oversee/leave/leavesetting",
    component: _229afca8,
    name: "oversee-leave-leavesetting"
  }, {
    path: "/oversee/leave/leavestatus",
    component: _464eaf74,
    name: "oversee-leave-leavestatus"
  }, {
    path: "/oversee/leave/usesetting",
    component: _445238c8,
    name: "oversee-leave-usesetting"
  }, {
    path: "/oversee/leave/usestatus",
    component: _47a84856,
    name: "oversee-leave-usestatus"
  }, {
    path: "/oversee/pcoff/pcoffexception",
    component: _d5f0493e,
    name: "oversee-pcoff-pcoffexception"
  }, {
    path: "/oversee/pcoff/pcoffsetting",
    component: _5ee0313c,
    name: "oversee-pcoff-pcoffsetting"
  }, {
    path: "/oversee/pcoff/pcoffstatus",
    component: _4f7cb3d0,
    name: "oversee-pcoff-pcoffstatus"
  }, {
    path: "/oversee/policies/additionalwork",
    component: _7ce4c868,
    name: "oversee-policies-additionalwork"
  }, {
    path: "/oversee/policies/policiescommon",
    component: _e4d4a462,
    name: "oversee-policies-policiescommon"
  }, {
    path: "/oversee/policies/policiesschedule",
    component: _07e7450a,
    name: "oversee-policies-policiesschedule"
  }, {
    path: "/oversee/policies/policiesteam",
    component: _25feff7e,
    name: "oversee-policies-policiesteam"
  }, {
    path: "/oversee/policies/policiesuser",
    component: _4092c2af,
    name: "oversee-policies-policiesuser"
  }, {
    path: "/oversee/user/admin",
    component: _7fb680b4,
    name: "oversee-user-admin"
  }, {
    path: "/oversee/user/profile",
    component: _9efd9580,
    name: "oversee-user-profile"
  }, {
    path: "/oversee/user/setting",
    component: _c2bde332,
    name: "oversee-user-setting"
  }, {
    path: "/oversee/workingattitude/workingattitudesetting",
    component: _3509dee4,
    name: "oversee-workingattitude-workingattitudesetting"
  }, {
    path: "/oversee/workingattitude/workingattitudestatus",
    component: _7c04c40e,
    name: "oversee-workingattitude-workingattitudestatus"
  }, {
    path: "/oversee/workingtime/workingtime",
    component: _0ff290e2,
    name: "oversee-workingtime-workingtime"
  }, {
    path: "/",
    component: _77b70e17,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
