import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_5bde5a11 from 'nuxt_plugin_plugin_5bde5a11' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_6f127f6b from 'nuxt_plugin_sentryserver_6f127f6b' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_09590de3 from 'nuxt_plugin_sentryclient_09590de3' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_vuedaumpostcode_e38fc8c2 from 'nuxt_plugin_vuedaumpostcode_e38fc8c2' // Source: ./vue-daum-postcode.js (mode: 'all')
import nuxt_plugin_axios_ce9b52b2 from 'nuxt_plugin_axios_ce9b52b2' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_googleanalytics_6a46231f from 'nuxt_plugin_googleanalytics_6a46231f' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_corejs_1fb72201 from 'nuxt_plugin_corejs_1fb72201' // Source: ../plugins/core-js (mode: 'all')
import nuxt_plugin_polyfill_14930cc5 from 'nuxt_plugin_polyfill_14930cc5' // Source: ../plugins/polyfill (mode: 'all')
import nuxt_plugin_bootstrap_0e119090 from 'nuxt_plugin_bootstrap_0e119090' // Source: ../plugins/bootstrap (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_sweetalert_4d5e1f3c from 'nuxt_plugin_sweetalert_4d5e1f3c' // Source: ../plugins/sweetalert (mode: 'all')
import nuxt_plugin_lodash_52e6a2ea from 'nuxt_plugin_lodash_52e6a2ea' // Source: ../plugins/lodash (mode: 'all')
import nuxt_plugin_veevalidate_6e5ad03a from 'nuxt_plugin_veevalidate_6e5ad03a' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_datatables_0db60685 from 'nuxt_plugin_datatables_0db60685' // Source: ../plugins/datatables (mode: 'all')
import nuxt_plugin_jstree_593a4146 from 'nuxt_plugin_jstree_593a4146' // Source: ../plugins/jstree (mode: 'all')
import nuxt_plugin_vuetabs_165afdce from 'nuxt_plugin_vuetabs_165afdce' // Source: ../plugins/vuetabs (mode: 'all')
import nuxt_plugin_vueselect_0c6a19ce from 'nuxt_plugin_vueselect_0c6a19ce' // Source: ../plugins/vue-select (mode: 'all')
import nuxt_plugin_vueformwizard_019bead2 from 'nuxt_plugin_vueformwizard_019bead2' // Source: ../plugins/vue-form-wizard (mode: 'all')
import nuxt_plugin_vuemask_9dbcaf6e from 'nuxt_plugin_vuemask_9dbcaf6e' // Source: ../plugins/vue-mask (mode: 'all')
import nuxt_plugin_vuectkdate_497b7a5e from 'nuxt_plugin_vuectkdate_497b7a5e' // Source: ../plugins/vue-ctk-date (mode: 'all')
import nuxt_plugin_vuemoment_1fc97fc6 from 'nuxt_plugin_vuemoment_1fc97fc6' // Source: ../plugins/vue-moment (mode: 'all')
import nuxt_plugin_vueeditor_3c5040ec from 'nuxt_plugin_vueeditor_3c5040ec' // Source: ../plugins/vue-editor (mode: 'all')
import nuxt_plugin_vueeasyslider_7a6ae50f from 'nuxt_plugin_vueeasyslider_7a6ae50f' // Source: ../plugins/vue-easy-slider (mode: 'all')
import nuxt_plugin_vuemonthlypicker_1cd37b6b from 'nuxt_plugin_vuemonthlypicker_1cd37b6b' // Source: ../plugins/vue-monthly-picker (mode: 'all')
import nuxt_plugin_vueinputtag_746d47da from 'nuxt_plugin_vueinputtag_746d47da' // Source: ../plugins/vue-input-tag (mode: 'all')
import nuxt_plugin_vuetogglebutton_f0aa71e4 from 'nuxt_plugin_vuetogglebutton_f0aa71e4' // Source: ../plugins/vue-toggle-button (mode: 'all')
import nuxt_plugin_vueclipboard2_3c353cf9 from 'nuxt_plugin_vueclipboard2_3c353cf9' // Source: ../plugins/vue-clipboard2 (mode: 'all')
import nuxt_plugin_vuehtmltopaper_b680a242 from 'nuxt_plugin_vuehtmltopaper_b680a242' // Source: ../plugins/vue-html-to-paper (mode: 'all')
import nuxt_plugin_vueapexcharts_a49918d8 from 'nuxt_plugin_vueapexcharts_a49918d8' // Source: ../plugins/vue-apexcharts (mode: 'all')
import nuxt_plugin_vtooltip_74057214 from 'nuxt_plugin_vtooltip_74057214' // Source: ../plugins/v-tooltip (mode: 'all')
import nuxt_plugin_sentry_3c12fa56 from 'nuxt_plugin_sentry_3c12fa56' // Source: ../plugins/sentry (mode: 'all')
import nuxt_plugin_mixintreefilter_2e4d7885 from 'nuxt_plugin_mixintreefilter_2e4d7885' // Source: ../plugins/mixin-tree-filter (mode: 'all')
import nuxt_plugin_mixinchecktreefilter_3026a680 from 'nuxt_plugin_mixinchecktreefilter_3026a680' // Source: ../plugins/mixin-check-tree-filter (mode: 'all')
import nuxt_plugin_mixinrowposition_af221904 from 'nuxt_plugin_mixinrowposition_af221904' // Source: ../plugins/mixin-row-position (mode: 'all')
import nuxt_plugin_mixindatatable_0b885c3c from 'nuxt_plugin_mixindatatable_0b885c3c' // Source: ../plugins/mixin-datatable (mode: 'all')
import nuxt_plugin_mixindayname_cbc37244 from 'nuxt_plugin_mixindayname_cbc37244' // Source: ../plugins/mixin-day-name (mode: 'all')
import nuxt_plugin_mixinpolicies_4a5d3e12 from 'nuxt_plugin_mixinpolicies_4a5d3e12' // Source: ../plugins/mixin-policies (mode: 'all')
import nuxt_plugin_mixinchange_a928ae1c from 'nuxt_plugin_mixinchange_a928ae1c' // Source: ../plugins/mixin-change (mode: 'all')
import nuxt_plugin_plugin_61918aa3 from 'nuxt_plugin_plugin_61918aa3' // Source: ./auth/plugin.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"OfficeBalance","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"OfficeBalance"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"preload","href":"\u002Ffonts\u002Fnoto\u002FNotoSans-Light.woff2","as":"font","type":"font\u002Fwoff2","crossorigin":"anonymous"},{"rel":"preload","href":"\u002Ffonts\u002Fnoto\u002FNotoSans-Regular.woff2","as":"font","type":"font\u002Fwoff2","crossorigin":"anonymous"},{"rel":"preload","href":"\u002Ffonts\u002Fnoto\u002FNotoSans-Bold.woff2","as":"font","type":"font\u002Fwoff2","crossorigin":"anonymous"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_5bde5a11 === 'function') {
    await nuxt_plugin_plugin_5bde5a11(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_6f127f6b === 'function') {
    await nuxt_plugin_sentryserver_6f127f6b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_09590de3 === 'function') {
    await nuxt_plugin_sentryclient_09590de3(app.context, inject)
  }

  if (typeof nuxt_plugin_vuedaumpostcode_e38fc8c2 === 'function') {
    await nuxt_plugin_vuedaumpostcode_e38fc8c2(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_ce9b52b2 === 'function') {
    await nuxt_plugin_axios_ce9b52b2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_6a46231f === 'function') {
    await nuxt_plugin_googleanalytics_6a46231f(app.context, inject)
  }

  if (typeof nuxt_plugin_corejs_1fb72201 === 'function') {
    await nuxt_plugin_corejs_1fb72201(app.context, inject)
  }

  if (typeof nuxt_plugin_polyfill_14930cc5 === 'function') {
    await nuxt_plugin_polyfill_14930cc5(app.context, inject)
  }

  if (typeof nuxt_plugin_bootstrap_0e119090 === 'function') {
    await nuxt_plugin_bootstrap_0e119090(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_sweetalert_4d5e1f3c === 'function') {
    await nuxt_plugin_sweetalert_4d5e1f3c(app.context, inject)
  }

  if (typeof nuxt_plugin_lodash_52e6a2ea === 'function') {
    await nuxt_plugin_lodash_52e6a2ea(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_6e5ad03a === 'function') {
    await nuxt_plugin_veevalidate_6e5ad03a(app.context, inject)
  }

  if (typeof nuxt_plugin_datatables_0db60685 === 'function') {
    await nuxt_plugin_datatables_0db60685(app.context, inject)
  }

  if (typeof nuxt_plugin_jstree_593a4146 === 'function') {
    await nuxt_plugin_jstree_593a4146(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetabs_165afdce === 'function') {
    await nuxt_plugin_vuetabs_165afdce(app.context, inject)
  }

  if (typeof nuxt_plugin_vueselect_0c6a19ce === 'function') {
    await nuxt_plugin_vueselect_0c6a19ce(app.context, inject)
  }

  if (typeof nuxt_plugin_vueformwizard_019bead2 === 'function') {
    await nuxt_plugin_vueformwizard_019bead2(app.context, inject)
  }

  if (typeof nuxt_plugin_vuemask_9dbcaf6e === 'function') {
    await nuxt_plugin_vuemask_9dbcaf6e(app.context, inject)
  }

  if (typeof nuxt_plugin_vuectkdate_497b7a5e === 'function') {
    await nuxt_plugin_vuectkdate_497b7a5e(app.context, inject)
  }

  if (typeof nuxt_plugin_vuemoment_1fc97fc6 === 'function') {
    await nuxt_plugin_vuemoment_1fc97fc6(app.context, inject)
  }

  if (typeof nuxt_plugin_vueeditor_3c5040ec === 'function') {
    await nuxt_plugin_vueeditor_3c5040ec(app.context, inject)
  }

  if (typeof nuxt_plugin_vueeasyslider_7a6ae50f === 'function') {
    await nuxt_plugin_vueeasyslider_7a6ae50f(app.context, inject)
  }

  if (typeof nuxt_plugin_vuemonthlypicker_1cd37b6b === 'function') {
    await nuxt_plugin_vuemonthlypicker_1cd37b6b(app.context, inject)
  }

  if (typeof nuxt_plugin_vueinputtag_746d47da === 'function') {
    await nuxt_plugin_vueinputtag_746d47da(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetogglebutton_f0aa71e4 === 'function') {
    await nuxt_plugin_vuetogglebutton_f0aa71e4(app.context, inject)
  }

  if (typeof nuxt_plugin_vueclipboard2_3c353cf9 === 'function') {
    await nuxt_plugin_vueclipboard2_3c353cf9(app.context, inject)
  }

  if (typeof nuxt_plugin_vuehtmltopaper_b680a242 === 'function') {
    await nuxt_plugin_vuehtmltopaper_b680a242(app.context, inject)
  }

  if (typeof nuxt_plugin_vueapexcharts_a49918d8 === 'function') {
    await nuxt_plugin_vueapexcharts_a49918d8(app.context, inject)
  }

  if (typeof nuxt_plugin_vtooltip_74057214 === 'function') {
    await nuxt_plugin_vtooltip_74057214(app.context, inject)
  }

  if (typeof nuxt_plugin_sentry_3c12fa56 === 'function') {
    await nuxt_plugin_sentry_3c12fa56(app.context, inject)
  }

  if (typeof nuxt_plugin_mixintreefilter_2e4d7885 === 'function') {
    await nuxt_plugin_mixintreefilter_2e4d7885(app.context, inject)
  }

  if (typeof nuxt_plugin_mixinchecktreefilter_3026a680 === 'function') {
    await nuxt_plugin_mixinchecktreefilter_3026a680(app.context, inject)
  }

  if (typeof nuxt_plugin_mixinrowposition_af221904 === 'function') {
    await nuxt_plugin_mixinrowposition_af221904(app.context, inject)
  }

  if (typeof nuxt_plugin_mixindatatable_0b885c3c === 'function') {
    await nuxt_plugin_mixindatatable_0b885c3c(app.context, inject)
  }

  if (typeof nuxt_plugin_mixindayname_cbc37244 === 'function') {
    await nuxt_plugin_mixindayname_cbc37244(app.context, inject)
  }

  if (typeof nuxt_plugin_mixinpolicies_4a5d3e12 === 'function') {
    await nuxt_plugin_mixinpolicies_4a5d3e12(app.context, inject)
  }

  if (typeof nuxt_plugin_mixinchange_a928ae1c === 'function') {
    await nuxt_plugin_mixinchange_a928ae1c(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_61918aa3 === 'function') {
    await nuxt_plugin_plugin_61918aa3(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    router.push(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
