import Vue from 'vue'
import Raven from 'raven-js'

export default ({ route, redirect, app }) => {
  if (route.name === 'login' && route.path !== '/login') {
    return true
  }

  if (
    route.path !== '/login' &&
    /^\/find\//i.test(route.path) === false &&
    /^\/join\//i.test(route.path) === false &&
    /^\/pdf\//i.test(route.path) === false &&
    !app.$auth.loggedIn
  ) {
    return redirect('/login')
  }

  if (app.$auth.loggedIn) {
    // Sentry Raven Context 추가 정보
    Raven.setUserContext({
      email: app.$auth.user.user.email
    })

    Raven.setExtraContext(app.$auth.$storage._state)
    Raven.setExtraContext(app.$auth.user)

    if (route.path === '/') {
      return redirect('/oversee/dashboard')
    }

    if (
      /^\/oversee\/\b(dashboard|policies\/policiesschedule|workingtime|workingattitude\/workingattitudestatus|leave\/leavestatus|pcoff\/pcoffstatus|help|alarm|user)|login/i.test(
        route.path
      ) === false &&
      app.$auth.loggedIn === true &&
      app.$auth.user.manager &&
      app.$auth.user.manager.access_level <= 40
    ) {
      Vue.swal({
        html:
          '<h2 class="font-icon icon-unauth color-danger mb-2"></h2>권한이 없습니다.',
        width: 400
      })
      return redirect('/oversee/dashboard')
    }

    if (
      /^\/oversee\/pcoff/i.test(route.path) === true &&
      app.$auth.user.acr.admin_site.pages.pcoff.accessible === false
    ) {
      return redirect('/oversee/dashboard')
    }
  }
}
