export default {
  ko: {
    messages: {
      _default: function(n) {
        return "'" + n + "' 항목의 값이 유효하지 않습니다."
      },
      after: function(n, e) {
        return (
          "'" +
          n +
          "' 항목의 값은 " +
          e[0] +
          ' 항목의 값 이후' +
          (e[1] ? '거나 같은 날이어야' : '여야') +
          ' 합니다.'
        )
      },
      alpha: function(n) {
        return "'" + n + "' 항목에는 영문자만 사용 가능합니다."
      },
      alpha_dash: function(n) {
        return (
          "'" +
          n +
          "' 항목에는 영문자, 숫자와 특수기호(-),(_)만 사용 가능합니다."
        )
      },
      alpha_num: function(n) {
        return "'" + n + "' 항목에는 영문자와 숫자만 사용 가능합니다."
      },
      alpha_spaces: function(n) {
        return "'" + n + "' 항목에는 영문자와 공백만 사용 가능합니다."
      },
      before: function(n, e) {
        return (
          "'" +
          n +
          "' 항목의 값은 " +
          e[0] +
          ' 항목의 값 이전' +
          (e[1] ? '이거나 같은 날' : '') +
          '이어야 합니다.'
        )
      },
      between: function(n, e) {
        return (
          "'" +
          n +
          "' 항목의 값은 " +
          e[0] +
          '에서 ' +
          e[1] +
          ' 사이여야 합니다.'
        )
      },
      confirmed: function(n) {
        return "'" + n + "' 항목의 값이 일치하지 않습니다."
      },
      credit_card: function(n) {
        return "'" + n + "' 항목의 값이 유효하지 않습니다."
      },
      date_between: function(n, e) {
        return (
          "'" +
          n +
          "' 항목의 값은 " +
          e[0] +
          '과 ' +
          e[1] +
          ' 사이의 날짜이어야 합니다.'
        )
      },
      date_format: function(n, e) {
        return "'" + n + "' 항목의 값은 " + e[0] + '형식이어야 합니다.'
      },
      decimal: function(n, e) {
        void 0 === e && (e = [])
        var t = e[0]
        return (
          void 0 === t && (t = '*'),
          "'" +
            n +
            "' 항목의 값은 숫자이어야 하며, 소수점 이하 " +
            (t && '*' !== t ? t : '') +
            '자리까지 사용 가능합니다.'
        )
      },
      digits: function(n, e) {
        return "'" + n + "' 항목의 값은 " + e[0] + '자리의 숫자이어야 합니다.'
      },
      dimensions: function(n, e) {
        return (
          "'" +
          n +
          "' 항목의 크기는 가로 " +
          e[0] +
          '픽셀, 세로 ' +
          e[1] +
          '픽셀이어야 합니다.'
        )
      },
      email: function(n) {
        return "'" + n + "' 항목의 값은 유효한 이메일 형식이어야 합니다."
      },
      excluded: function(n) {
        return "'" + n + "' 항목은 유효한 값이어야 합니다."
      },
      ext: function(n) {
        return "'" + n + "' 항목은 유효한 파일이어야 합니다."
      },
      image: function(n) {
        return "'" + n + "' 항목은 이미지 파일이어야 합니다."
      },
      included: function(n) {
        return "'" + n + "' 항목의 값은 유효한 값이어야 합니다."
      },
      integer: function(n) {
        return "'" + n + "' 항목의 값은 정수이어야 합니다."
      },
      ip: function(n) {
        return "'" + n + "' 항목의 값은 유효한 IP(ipv4) 주소이어야 합니다."
      },
      length: function(n, e) {
        var t = e[0],
          r = e[1]
        return r
          ? "'" + n + "' 항목의 값은 " + t + '자에서 ' + r + '자이어야 합니다.'
          : "'" + n + "' 항목의 값은 " + t + '자이어야 합니다.'
      },
      max: function(n, e) {
        return "'" + n + "' 항목의 값은 최대 " + e[0] + '글자이어야 합니다.'
      },
      max_value: function(n, e) {
        return "'" + n + "' 항목의 값은 " + e[0] + ' 이하이어야 합니다.'
      },
      mimes: function(n) {
        return "'" + n + ' 는 유효한 파일 형식의 파일이어야 합니다.'
      },
      min: function(n, e) {
        return "'" + n + "' 항목의 값은 최소 " + e[0] + '글자이어야 합니다.'
      },
      min_value: function(n, e) {
        return "'" + n + "' 항목의 값은 " + e[0] + ' 이상이어야 합니다.'
      },
      numeric: function(n) {
        return "'" + n + "' 항목에는 숫자만 사용 가능합니다."
      },
      regex: function(n) {
        return "'" + n + "' 항목은 형식에 맞지 않습니다."
      },
      required: function(n) {
        return "'" + n + "' 항목은 필수 정보입니다."
      },
      size: function(n, e) {
        var t,
          r,
          u,
          i = e[0]
        return (
          "'" +
          n +
          "' 항목의 크기는 " +
          ((t = i),
          (r = 1024),
          (u =
            0 == (t = Number(t) * r)
              ? 0
              : Math.floor(Math.log(t) / Math.log(r))),
          1 * (t / Math.pow(r, u)).toFixed(2) +
            ' ' +
            ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][u]) +
          '보다 작아야 합니다.'
        )
      },
      url: function(n) {
        return "'" + n + "' 항목의 값은 유효한 주소(URL)가 아닙니다."
      }
    }
  }
}
