import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
window.$ = require('jquery')

Vue.use(VueSweetalert2)

Vue.swal = Vue.swal.mixin({
  confirmButtonText: '확인',
  cancelButtonText: '취소'
})

// MultiModal Class
window.$(document).on('hidden.bs.modal', '.modal', function() {
  if (window.$('.modal:visible').length !== 0) {
    window.$(document.body).addClass('modal-open')
  } else {
    window.$('.modal').modal('hide')
  }
})
