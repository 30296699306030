import Vue from 'vue'

Vue.mixin({
  watch: {
    weekly_schedule: {
      handler: function(list) {
        window._.forEach(list, val => {
          window._.forEach(val, data => {
            this.scheduleCalc(data)
          })
        })
      },
      deep: true
    }
  },
  methods: {
    nl2br(val) {
      return window._.replace(val, /\n/g, '<br />')
    },
    dateHourFormat(date) {
      if (date !== undefined && date !== null) {
        return Vue.moment(date).format('HH:mm')
      }
    },
    minuteFormat(minute) {
      return Vue.moment()
        .startOf('day')
        .add(minute, 'minutes')
        .format('HH:mm')
    },
    workDisplay(data, row) {
      if (row.status !== undefined && row.status.length >= 1) {
        let text = []

        window._.forEach(row.status, status => {
          if (status === 'business-trip') {
            text.push(
              '<span class="badge badge-secondary-bgsblue badge_table">출장</span>'
            )
          } else if (status === 'local-commute-in') {
            text.push(
              '<span class="badge badge-secondary-bgsblue badge_table">현지출근</span>'
            )
          } else if (status === 'local-commute-out') {
            text.push(
              '<span class="badge badge-secondary-bgsblue badge_table">현지퇴근</span>'
            )
          } else if (status === 'at-home-work') {
            text.push(
              '<span class="badge badge-secondary-bgsblue badge_table">재택근무</span>'
            )
          } else if (status === 'leave') {
            text.push(
              '<span class="badge badge-green-bglgreen badge_table"><em class="font-icon icon-leave"></em>휴가</span>'
            )
          } else if (status === 'half-leave' || status === 'quarter-leave') {
            text.push(
              '<span class="badge badge-green-bglgreen badge_table"><em class="font-icon icon-leave"></em>휴가(일부)</span>'
            )
          } else if (status === 'leave-na') {
            text.push(
              '<span class="badge badge-green-bglgreen badge_table"><em class="font-icon icon-leave"></em>휴가(불)</span>'
            )
          } else if (
            status === 'half-leave-na' ||
            status === 'quarter-leave-na'
          ) {
            text.push(
              '<span class="badge badge-green-bglgreen badge_table"><em class="font-icon icon-leave"></em>휴가(불/일부)</span>'
            )
          } else if (status === 'anniversary-holiday') {
            text.push(
              `<span class="badge badge-green-bglgreen badge_table" title="${
                row.holiday.name
              }"><em class="font-icon icon-public_holiday"></em>${this.subStr(
                row.holiday.name,
                8
              )}</span>`
            )
          } else if (
            status === 'primary-holiday' ||
            status === 'supplement-holiday'
          ) {
            if (row.holiday.duty === true) {
              text.push(
                '<span class="badge badge-green-bglgreen badge_table">휴무</span>'
              )
            } else {
              text.push(
                `<span class="badge badge-green-bglgreen badge_table">휴일</span>`
              )
            }
          }
        })

        if (text.length === 0) {
          text.push('-')
        }

        data = `${text.join('/')}`
      } else {
        if (row.working_hour_schedule[0].start_at === null) {
          data =
            '<span class="badge badge-danger-bggrey badge_table">미등록</span>'
        } else {
          data =
            '<span class="badge badge-black-bggrey badge_table">근무</span>'
        }
      }

      return data
    },
    secondDisplay(second) {
      let text = '-'

      if (!isNaN(second) && second !== 0) {
        text = `${
          second / 60 / 60 >= 1 ? Math.floor(second / 60 / 60) + '시간' : ''
        }${second % 3600 !== 0 ? Math.floor((second % 3600) / 60) + '분' : ''}`
      }

      return text
    },
    minuteDisplay(minute) {
      let text = '-'

      if (!isNaN(minute) && minute !== 0) {
        text = `${minute / 60 >= 1 ? Math.floor(minute / 60) + '시간' : ''}${
          minute % 60 !== 0 ? Math.floor(minute % 60) + '분' : ''
        }`
      }

      return text
    },
    minuteDivideDisplay(minute, hourDisplay = false, minuteDisplay = false) {
      if (!isNaN(minute)) {
        if (hourDisplay) {
          return minute / 60 >= 1 ? Math.floor(minute / 60) : '0'
        }

        if (minuteDisplay) {
          return minute % 60 !== 0 ? Math.floor(minute % 60) : '0'
        }
      } else {
        return '0시간'
      }
    },
    displayFullDate: function(val) {
      return Vue.moment(val).format('YYYY년 MM월 DD일')
    },
    displayDate: function(val) {
      return Vue.moment(val).format('MM-DD')
    },
    displayDay: function(val) {
      return Vue.moment(val).format('ddd')
    },
    prev7Day: function() {
      return Vue.moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD')
    },
    workInfo(data, row) {
      let field = '-'
      let info = []

      if (
        row.working_hour_schedule.length !== 0 &&
        row.working_hour_system_type !== null
      ) {
        window._.forEach(row.working_hour_schedule, val => {
          if (
            val.start_at !== null &&
            val.to_start_at !== null &&
            val.end_at !== null &&
            val.to_end_at !== null
          ) {
            info.push(
              `${this.dateHourFormat(val.start_at)}/${this.dateHourFormat(
                val.to_start_at
              )} ~ <br/>${this.dateHourFormat(
                val.end_at
              )}/${this.dateHourFormat(val.to_end_at)}${
                val.date !== Vue.moment(val.end_at).format('YYYY-MM-DD') ||
                val.date !== Vue.moment(val.to_end_at).format('YYYY-MM-DD')
                  ? '(익일)'
                  : ''
              }`
            )
          } else if (val.start_at !== null && val.end_at !== null) {
            info.push(
              `${this.dateHourFormat(val.start_at)} ~ ${this.dateHourFormat(
                val.end_at
              )}${
                val.date !== Vue.moment(val.end_at).format('YYYY-MM-DD')
                  ? '(익일)'
                  : ''
              }`
            )
          }
        })

        field = info.join('<br/>')
      }

      return field
    },
    addWorkInfo(data, row) {
      let field = '-'
      let info = []

      if (row.overtimes.length !== 0 && row.working_hour_system_type !== null) {
        window._.forEach(row.overtimes, val => {
          if (val.start_at !== null && val.end_at !== null) {
            info.push(
              `${this.dateHourFormat(val.start_at)} ~ ${this.dateHourFormat(
                val.end_at
              )}${
                val.date !== Vue.moment(val.end_at).format('YYYY-MM-DD')
                  ? '(익일)'
                  : ''
              }`
            )
          }
        })

        field = info.join('<br/>')
      }

      return field
    },
    fixedWokingDisplay(data, row) {
      let field = '-'

      if (row.working_hour_schedule.length !== 0) {
        field = this.minuteDisplay(
          this.sumBy(row.working_hour_schedule, 'fixed_working_min')
        )
      }

      return field
    },
    addWokingDisplay(data, row) {
      let field = '-'

      if (row.working_hour_schedule.length !== 0) {
        field = this.minuteDisplay(
          this.sumBy(row.working_hour_schedule, 'additional_working_min')
        )
      }

      return field
    },
    breakDisplay(data, row) {
      let field = '-'

      if (row.working_hour_schedule.length !== 0) {
        field = this.minuteDisplay(
          this.sumBy(row.working_hour_schedule, 'break_min')
        )
      }

      return field
    },
    itemReset() {
      this.formData = {
        weekly_schedule: {
          mon: null,
          tue: null,
          wed: null,
          thu: null,
          fri: null,
          sat: null,
          sun: null
        },
        possible_schedule: {
          condition_type: 'required',
          weekly: {
            mon: null,
            tue: null,
            wed: null,
            thu: null,
            fri: null,
            sat: null,
            sun: null
          }
        },
        end_date: null
      }
    },
    itemCalc(val) {
      window._.forEach(val, item => {
        if (item !== null) {
          if (
            item.working_hour_system_type.code === 'W001' ||
            item.working_hour_system_type.code === 'W002' ||
            item.working_hour_system_type.code === 'W006'
          ) {
            item.break_time = 0
            item.fixed_working_time = 0

            // 요일 순서 정렬을 위한 초기화
            let weekly_schedule = {
              mon: {},
              tue: {},
              wed: {},
              thu: {},
              fri: {},
              sat: {},
              sun: {}
            }

            window._.forEach(item.weekly_schedule, (day, key) => {
              weekly_schedule[key] = day
            })

            item.weekly_schedule = weekly_schedule

            window._.forEach(item.weekly_schedule, day => {
              if (day !== null) {
                window._.forEach(day, data => {
                  item.break_time += data.break_min
                  item.fixed_working_time += data.fixed_working_min
                })
              }
            })
          } else if (item.working_hour_system_type.code === 'W003') {
            item.working_info = {}
            item.required_working_info = {}

            // 요일 순서 정렬을 위한 초기화
            let possible_schedule = {
              mon: {},
              tue: {},
              wed: {},
              thu: {},
              fri: {},
              sat: {},
              sun: {}
            }

            window._.forEach(item.possible_schedule.weekly, (day, key) => {
              possible_schedule[key] = day
            })

            item.possible_schedule.weekly = possible_schedule
          } else if (
            item.working_hour_system_type.code === 'W004' ||
            item.working_hour_system_type.code === 'W005'
          ) {
            item.working_info = {}
            item.required_working_info = {}

            window._.forEach(
              item.flexible_working_hour_schedule,
              (day, key) => {
                // 주차별 Group 키
                if (day.date) {
                  item.flexible_working_hour_schedule[key].week = Vue.moment(
                    day.date
                  )
                    .clone()
                    .isoWeek()
                }
              }
            )

            let flexFilter = []
            window._.forEach(item.flexible_working_hour_schedule, row => {
              if (row.start_time !== null && row.end_time !== null) {
                flexFilter.push({ date: row.date })
              }
            })

            const flexCount = window._.groupBy(flexFilter, 'date')
            item.flexCount = Object.keys(flexCount).length
          }
        }
      })
    },
    total_worktime() {
      let time = 0

      window._.forEach(this.formData.weekly_schedule, val => {
        window._.forEach(val, data => {
          if (data !== null && !isNaN(data.worktime)) {
            time += data.worktime
          }
        })
      })

      return time
    },
    total_breaktime() {
      let time = 0

      window._.forEach(this.formData.weekly_schedule, val => {
        window._.forEach(val, data => {
          if (data !== null && !isNaN(data.worktime)) {
            time += data.breaktime
          }
        })
      })

      return time
    },
    total_flexible_worktime(data) {
      const flexible_working_hour_schedule =
        data === undefined ? this.flexible_working_hour_schedule : data
      let time = 0

      window._.forEach(flexible_working_hour_schedule, val => {
        if (val !== null && !isNaN(val.fixed_working_min)) {
          time += val.fixed_working_min
        }
      })

      return time
    },
    total_approval_scheduletime(data) {
      const working_hour_schedule = data
      let time = 0

      window._.forEach(working_hour_schedule, val => {
        if (val !== null) {
          time += val.request_schedule_time
        }
      })

      return time
    },
    total_approval_worktime(data) {
      const working_hour_schedule = data
      let time = 0

      window._.forEach(working_hour_schedule, val => {
        if (val !== null) {
          time += val.request_work
        }
      })

      return time
    },
    total_approval_breaktime(data) {
      const working_hour_schedule = data
      let time = 0

      window._.forEach(working_hour_schedule, val => {
        if (val !== null) {
          time += val.request_rest
        }
      })

      return time
    },
    day_max_worktime(data) {
      const flexible_working_hour_schedule =
        data === undefined
          ? window._.values(this.flexible_working_hour_schedule)
          : window._.values(data)

      let time = window._.maxBy(
        flexible_working_hour_schedule,
        'fixed_working_min'
      )

      if (time !== undefined && time.fixed_working_min !== undefined) {
        return time.fixed_working_min
      } else {
        return 0
      }
    },
    week_max_worktime(data) {
      const flexible_working_hour_schedule =
        data === undefined
          ? window._.values(this.flexible_working_hour_schedule)
          : window._.values(data)

      if (flexible_working_hour_schedule !== undefined) {
        const group_time = window._.groupBy(
          flexible_working_hour_schedule.filter(
            row =>
              !isNaN(row['fixed_working_min']) && row['fixed_working_min'] !== 0
          ),
          'week'
        )

        const fixed_working_min = window._.map(group_time, (objs, key) => ({
          key: key,
          fixed_working_min: window._.sumBy(objs, 'fixed_working_min')
        }))

        let time = window._.maxBy(fixed_working_min, 'fixed_working_min')

        if (time !== undefined && time.fixed_working_min !== undefined) {
          return time.fixed_working_min
        } else {
          return 0
        }
      }
    },
    avg_total_flexible_worktime(data, unit) {
      const flexible_working_hour_schedule =
        data === undefined ? this.flexible_working_hour_schedule : data
      const period = unit === undefined ? this.period.amount : unit

      return (
        this.total_flexible_worktime(flexible_working_hour_schedule) / period
      )
    },

    dayduty(key, date = null, holiday = null) {
      // 연도 체크하여 Store 업데이트
      if (
        date !== null &&
        this.$store.state.holidays.data[0] !== undefined &&
        this.$store.state.holidays.data.filter(
          row =>
            Vue.moment(row['date']).format('YYYY') ===
            Vue.moment(date).format('YYYY')
        ).length === 0
      ) {
        const year = Vue.moment(date).format('YYYY')
        this.$store.dispatch('getHolidays', year)
      }

      let duty = this.$store.state.holidays

      if (duty.holiday.day === key && duty.holiday.holiday === true) {
        return 'primary-holiday'
      }

      if (
        duty.regular_holiday.day === key &&
        duty.regular_holiday.holiday === true
      ) {
        return 'supplement-holiday'
      }

      if (
        date !== null &&
        window._.findKey(duty.data, { date: date }) !== undefined
      ) {
        const target = window._.find(duty.data, { date: date })

        if (target.holiday === true) {
          return 'anniversary-holiday'
        }
      }

      if (holiday !== null && holiday.holiday === true) {
        if (holiday.type === 'primary') {
          return 'primary-holiday'
        } else if (holiday.type === 'supplement') {
          return 'supplement-holiday'
        } else {
          return 'anniversary-holiday'
        }
      }
    },
    daydutyColor(key, date = null) {
      // 연도 체크하여 Store 업데이트
      if (
        date !== null &&
        this.$store.state.holidays.data[0] !== undefined &&
        this.$store.state.holidays.data.filter(
          row =>
            Vue.moment(row['date']).format('YYYY') ===
            Vue.moment(date).format('YYYY')
        ).length === 0
      ) {
        const year = Vue.moment(date).format('YYYY')
        this.$store.dispatch('getHolidays', year)
      }

      let duty = this.$store.state.holidays

      if (duty.holiday.day === key && duty.holiday.holiday === true) {
        return 'color-danger'
      }

      if (
        duty.regular_holiday.day === key &&
        duty.regular_holiday.holiday === true
      ) {
        return 'color-officeblue'
      }

      if (
        date !== null &&
        window._.findKey(duty.data, { date: date }) !== undefined
      ) {
        const target = window._.find(duty.data, { date: date })

        if (target.holiday === true) {
          return 'color-danger'
        }
      }
    },
    scheduleCalc(val) {
      let start_time = Vue.moment(val.start_time, 'HH:mm')
      let end_time = Vue.moment(val.end_time, 'HH:mm')
      let time = 0
      let breaktime = 0

      if (start_time > end_time) {
        const start_target = Vue.moment('24:00', 'HH:mm')
        let start_cal = start_target.diff(start_time, 'minutes')

        const end_target = Vue.moment('00:00', 'HH:mm')
        let end_cal = end_time.diff(end_target, 'minutes')

        time += start_cal + end_cal
      } else {
        time += end_time.diff(start_time, 'minutes')
      }

      let break_time = Vue.moment(val.break_time, 'HH:mm')
      let to_break_time = Vue.moment(val.to_break_time, 'HH:mm')

      if (break_time > to_break_time) {
        const start_target = Vue.moment('24:00', 'HH:mm')
        let start_cal = start_target.diff(break_time, 'minutes')

        const end_target = Vue.moment('00:00', 'HH:mm')
        let end_cal = to_break_time.diff(end_target, 'minutes')

        breaktime += start_cal + end_cal
      } else {
        breaktime += to_break_time.diff(break_time, 'minutes')
      }

      val.worktime = isNaN(breaktime)
        ? isNaN(time)
          ? 0
          : time
        : time - breaktime
      val.breaktime = isNaN(breaktime) ? 0 : breaktime
    },
    flexScheduleCalc(val) {
      let time = 0

      window._.forEach(val, data => {
        if (data.start_time !== null || data.end_time !== null) {
          let start_time = Vue.moment(data.start_time, 'HH:mm')
          let end_time = Vue.moment(data.end_time, 'HH:mm')

          if (start_time > end_time) {
            const start_target = Vue.moment('24:00', 'HH:mm')
            let start_cal = start_target.diff(start_time, 'minutes')

            const end_target = Vue.moment('00:00', 'HH:mm')
            let end_cal = end_time.diff(end_target, 'minutes')

            time += start_cal + end_cal
          } else {
            if (data.start_time === null || data.end_time === null) {
              return false
            }

            time += end_time.diff(start_time, 'minutes')
          }
        } else {
          data.fixed_working_min = 0
          data.start_time = null
          data.end_time = null
          data.break_min = 0
        }
      })

      let caltime = time
      let breaktime = 0

      while (caltime >= 240) {
        caltime -= 270

        time = time - 30
        if (breaktime == undefined) {
          breaktime = 30
        } else {
          breaktime += 30
        }
      }

      val.fixed_working_min = isNaN(time) ? 0 : time
      val.break_min = isNaN(breaktime) ? 0 : breaktime
      val.week = val[0].week
    },
    workCalc(val, worktime = false, breaktime = false) {
      if (val.start_time !== null || val.end_time !== null) {
        let start_time = Vue.moment(val.start_time, 'HH:mm')
        let end_time = Vue.moment(val.end_time, 'HH:mm')
        let time

        if (start_time > end_time) {
          const start_target = Vue.moment('24:00', 'HH:mm')
          let start_cal = start_target.diff(start_time, 'minutes')

          const end_target = Vue.moment('00:00', 'HH:mm')
          let end_cal = end_time.diff(end_target, 'minutes')

          time = start_cal + end_cal
        } else {
          time = end_time.diff(start_time, 'minutes')
        }

        let caltime = time
        let break_time = 0

        while (caltime >= 240) {
          caltime -= 270

          time = time - 30
          break_time += 30
        }

        if (worktime) {
          return time
        }

        if (breaktime) {
          return break_time
        }
      } else {
        return 0
      }
    },
    computed_weekly_schedule() {
      if (this.formData.weekly_schedule === null) {
        this.formData.weekly_schedule = {
          mon: null,
          tue: null,
          wed: null,
          thu: null,
          fri: null,
          sat: null,
          sun: null
        }
      }

      let list = this.formData.weekly_schedule

      window._.forEach(list, (val, key) => {
        if (val === null || val.length === 0) {
          list[key] = [
            {
              break_min: '',
              break_time: '',
              end_time: '',
              fixed_working_min: '',
              start_time: '',
              to_break_time: '',
              to_end_time: '',
              to_start_time: ''
            }
          ]
        } else {
          window._.forEach(val, data => {
            this.scheduleCalc(data)
          })
        }
      })
      return list
    },
    computed_possible_schedule() {
      if (this.formData.possible_schedule === null) {
        this.formData.possible_schedule = {
          condition_type: 'required',
          weekly: null
        }
      }

      if (this.formData.possible_schedule.weekly === null) {
        this.formData.possible_schedule.weekly = {
          mon: null,
          tue: null,
          wed: null,
          thu: null,
          fri: null,
          sat: null,
          sun: null
        }
      }

      let list = this.formData.possible_schedule

      if (list.condition_type === undefined) {
        list.condition_type = 'required'
      }

      window._.forEach(list.weekly, (val, key) => {
        if (val === null) {
          list.weekly[key] = {
            start_time: '',
            end_time: '',
            required_start_time: '',
            required_end_time: '',
            max_minutes: '',
            min_minutes: '',
            default_start_time: '',
            default_end_time: ''
          }
        }
      })
      return list
    },
    computed_flexible_working_hour_schedule() {
      if (
        this.formData.start_date !== undefined &&
        this.formData.start_date !== null &&
        this.period.unit !== undefined &&
        this.period.amount !== undefined
      ) {
        let currDate
        let lastDate

        if (this.formData.flexible_working_hour_schedule === undefined) {
          this.formData.flexible_working_hour_schedule = []
          currDate = Vue.moment(this.formData.start_date)
          if (this.period.unit === 'week') {
            lastDate = Vue.moment(currDate)
              .day(0)
              .add(this.period.amount, 'weeks')
              .format('YYYY-MM-DD')
          } else {
            // 요번달 부터 계산되도록
            let lastCalc = Vue.moment(currDate).subtract(1, 'months')

            lastDate = Vue.moment(lastCalc)
              .add(this.period.amount, 'month')
              .endOf('month')
              .format('YYYY-MM-DD')
          }

          this.formData.flexible_working_hour_schedule.push({
            date: currDate.clone().format('YYYY-MM-DD'),
            week: currDate.clone().isoWeek(),
            fixed_working_time: '',
            start_time: '',
            end_time: null,
            working_time: 0,
            break_min: null
          })

          while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            this.formData.flexible_working_hour_schedule.push({
              date: currDate.clone().format('YYYY-MM-DD'),
              week: currDate.clone().isoWeek(),
              fixed_working_time: '',
              start_time: '',
              end_time: null,
              working_time: 0,
              break_min: null
            })
          }

          this.formData.end_date = lastDate
        }

        this.formData.flexible_working_hour_schedule = window._.groupBy(
          this.formData.flexible_working_hour_schedule,
          'date'
        )

        let list = this.formData.flexible_working_hour_schedule

        return list
      }
    },
    getDatesDiff(start_date, end_date, date_format = 'YYYY-MM-DD') {
      const getDateAsArray = date => {
        return Vue.moment(date.split(/\D+/), date_format)
      }
      const diff =
        getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1
      const dates = []
      for (let i = 0; i < diff; i++) {
        const nextDate = getDateAsArray(start_date).add(i, 'day')
        dates.push(nextDate.format(date_format))
      }
      return dates
    },
    flexibleControl() {
      const data = this.controlData

      // 단위기간/시작 미선택
      if (this.formData.flexible_working_hour_schedule === undefined) {
        return false
      }

      if (data.type == 'day') {
        window._.forEach(data.day, (val, key) => {
          // 체크여부 확인
          if (val === false) {
            return
          }

          if (data.timeType === 'work') {
            window._.forEach(
              this.formData.flexible_working_hour_schedule,
              formVal => {
                if (this.dayKey(formVal[0].date) === key) {
                  if (data.start_time !== undefined) {
                    formVal[0].start_time = data.start_time
                  }

                  if (data.end_time !== undefined) {
                    formVal[0].end_time = data.end_time
                  }
                }
              }
            )
          }
        })
      } else if (data.type == 'date') {
        // 날짜 선택 체크
        if (data.dateList.length === 0) {
          return false
        }

        if (data.timeType === 'work') {
          window._.forEach(data.dateList, val => {
            window._.forEach(
              this.formData.flexible_working_hour_schedule,
              formVal => {
                if (formVal[0].date === val) {
                  if (data.start_time !== undefined) {
                    formVal[0].start_time = data.start_time
                  }

                  if (data.end_time !== undefined) {
                    formVal[0].end_time = data.end_time
                  }
                }
              }
            )
          })
        }
      } else if (data.type == 'range') {
        // 날짜 선택 체크
        if (data.rangeList.length === 0) {
          return false
        }

        if (data.timeType === 'work') {
          window._.forEach(data.rangeList, date => {
            let idx = date.length / 2
            let start = date.substr(0, idx)
            let end = date.substr(idx + 1)

            if (start > end) {
              return false
            }

            const dates = this.getDatesDiff(start, end)

            window._.forEach(dates, val => {
              window._.forEach(
                this.formData.flexible_working_hour_schedule,
                formVal => {
                  if (formVal[0].date === val) {
                    if (data.start_time !== undefined) {
                      formVal[0].start_time = data.start_time
                    }

                    if (data.end_time !== undefined) {
                      formVal[0].end_time = data.end_time
                    }
                  }
                }
              )
            })
          })
        }
      }
    },
    sumBy(obj, key) {
      return window._.sumBy(obj, key)
    },
    toMinutes(time) {
      const value = Vue.moment(time)
      return value.minutes() + value.hours() * 60
    },
    twoDigit(val) {
      return ('00' + val).slice(-2)
    }
  }
})
