import Vue from 'vue'

Vue.mixin({
  methods: {
    rowAdd(row) {
      window._.forEach(this.rowsData, function(val) {
        if (row.position <= val.position) {
          val.position++
        }
      })

      this.rowsData.splice(row.position, 0, row)
    },
    rowMod(row) {
      const target = window._.find(this.rowsData, { id: row.id }).position

      this.$delete(
        this.rowsData,
        window._.findKey(this.rowsData, { id: row.id })
      )

      if (target !== row.position) {
        if (target < row.position) {
          window._.forEach(this.rowsData, function(val) {
            if (target <= val.position && row.position >= val.position) {
              val.position--
            }
          })
        } else {
          window._.forEach(this.rowsData, function(val) {
            if (target >= val.position && row.position <= val.position) {
              val.position++
            }
          })
        }
      }

      this.rowsData.splice(row.position, 0, row)
    },
    rowReset() {
      this.formData = {}
    }
  }
})
