window.$ = require('jquery')
const JSZip = require('jszip')

window.JSZip = JSZip
require('datatables.net-bs4')
require('datatables.net-buttons-bs4')
require('datatables.net-buttons/js/buttons.html5.js')
require('datatables.net-plugins/api/processing().js')

window.$.fn.dataTable.ext.errMode = 'none'

window.$.extend(true, window.$.fn.dataTable.defaults, {
  deferRender: true,
  processing: true,
  autoWidth: false,
  language: {
    emptyTable: '데이터가 없습니다.',
    info: '_START_ - _END_ / _TOTAL_',
    infoEmpty: '0 - 0 / 0',
    infoFiltered: '(총 _MAX_ 개)',
    infoPostFix: '',
    infoThousands: ',',
    lengthMenu:
      '<select class="form-control form-control-sm">' +
      '<option value="10">10개씩 보기</option>' +
      '<option value="20">20개씩 보기</option>' +
      '<option value="50">50개씩 보기</option>' +
      '</select>',
    loadingRecords: '읽는중...',
    processing:
      '<span class="table-loading"></span><span class="sr-only">Loading...</span>',
    search: '<em class="font-icon icon-search"></em>',
    searchPlaceholder: '검색어를 입력하세요.',
    zeroRecords: '검색 결과가 없습니다',
    paginate: {
      first: '처음',
      last: '마지막',
      next: '<em class="font-icon icon-arrow_right_double"></em>',
      previous: '<em class="font-icon icon-arrow_left_double"></em>'
    },
    aria: {
      sortAscending: '오름차순 정렬',
      sortDescending: '내림차순 정렬'
    }
  },
  dom:
    '<"d-flex justify-content-end" lf><tr><"d-flex justify-content-center" p<"ml-3" i>>',
  pageLength: 10
})
