//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderMenu from '~/components/HeaderMenu'
import WebHeaderMenu from '~/components/WebHeaderMenu'

export default {
  components: {
    HeaderMenu,
    WebHeaderMenu
  },
  computed: {
    logoHref: function() {
      return this.$auth.loggedIn ? '/oversee/dashboard' : '/'
    }
  },
  mounted() {
    window
      .$('.navbar-nav > li > a:not(.dropdown-toggle), .dropdown-menu > a')
      .on('click', function() {
        window.$('.navbar-collapse').collapse('hide')
      })

    this.$store.dispatch('getCompany')

    window
      .$(window)
      .resize(() => {
        if (window.$(window).width() > 768) {
          window.$('#navbarCollapse').removeClass('show')
        } else {
          this.$parent.collapsed = false
        }
      })
      .resize()
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout')
    }
  }
}
