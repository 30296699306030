import Vue from 'vue'

Vue.mixin({
  watch: {
    checkTreeData: function() {
      this.checkTreeLoad()
    },
    checkTreeKeyword: function() {
      this.checkTreeSearch()
    }
  },
  methods: {
    checkTyping: function(e) {
      this.checkTreeKeyword = e.target.value
    },
    checkTreeLayer() {
      this.checkTreeVisible = !this.checkTreeVisible
      if (!this.checkTreeVisible) {
        this.checkTreeKeyword = ''
      }
    },
    checkTreeAway: function() {
      this.checkTreeVisible = false
      this.checkTreeKeyword = ''
    },
    checkTreeSearch: window._.debounce(function() {
      if (window.$('#checkTree').text() !== '등록된 내역이 없습니다') {
        window
          .$('#checkTree')
          .jstree(true)
          .search(this.checkTreeKeyword)
      }
    }, 250),
    checkTreeLoad: function() {
      if (this.jscheckTree !== null) {
        this.jscheckTree.jstree('destroy').empty()
      }

      this.jscheckTree = window
        .$('#checkTree')
        .jstree({
          core: {
            check_callback: function(op, node, parent, position, more) {
              if (op === 'move_node' && more && more.core) {
                if (parent.type === '#') {
                  return false
                }
              }
            },
            data: this.checkTreeData,
            themes: {
              ellipsis: true
            }
          },
          sort: function(a, b) {
            const a_node = this.get_node(a)
            const b_node = this.get_node(b)

            if (a_node.type !== b_node.type) {
              return /^user_/g.test(a) ? -1 : 1
            }
          },
          search: {
            case_insensitive: true,
            show_only_matches: true,
            show_only_matches_children: true
          },
          types: {
            company: {
              icon: 'font-icon icon-building'
            },
            department: {
              icon: 'font-icon icon-folder_solid'
            },
            user: {
              icon: 'font-icon icon-user_solid'
            }
          },
          plugins: ['search', 'types', 'checkbox', 'sort']
        })
        .bind('ready.jstree', () => {
          this.checkTreeKeyword = ''
          this.checkTreeSelectData = []
        })
        .on('loaded.jstree', () => {
          window.$('#checkTree').jstree('open_all')
        })
        .on('changed.jstree', () => {
          this.checkTreeSelectData = window
            .$('#checkTree')
            .jstree('get_selected', true)
        })
    }
  }
})
