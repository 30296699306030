import Vue from 'vue'

Vue.mixin({
  data() {
    return {
      daytitle: {
        mon: '월',
        tue: '화',
        wed: '수',
        thu: '목',
        fri: '금',
        sat: '토',
        sun: '일'
      }
    }
  },
  methods: {
    dayname(val) {
      return this.daytitle[val]
    },
    dayKey(val) {
      const title = Vue.moment(val).format('ddd')

      return window._.findKey(
        this.daytitle,
        window._.partial(window._.isEqual, title)
      )
    },
    dateName(val) {
      return Vue.moment(val).format('ddd')
    }
  }
})
