import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  HeaderMenu: () => import('../../components/HeaderMenu.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c)),
  LoadingActive: () => import('../../components/LoadingActive.vue' /* webpackChunkName: "components/loading-active" */).then(c => wrapFunctional(c.default || c)),
  NaviBar: () => import('../../components/NaviBar.vue' /* webpackChunkName: "components/navi-bar" */).then(c => wrapFunctional(c.default || c)),
  WebHeaderMenu: () => import('../../components/WebHeaderMenu.vue' /* webpackChunkName: "components/web-header-menu" */).then(c => wrapFunctional(c.default || c)),
  OverseeAgentInstallFilter: () => import('../../components/oversee/agent/AgentInstallFilter.vue' /* webpackChunkName: "components/oversee-agent-install-filter" */).then(c => wrapFunctional(c.default || c)),
  OverseeAgentInstallTimeFilter: () => import('../../components/oversee/agent/AgentInstallTimeFilter.vue' /* webpackChunkName: "components/oversee-agent-install-time-filter" */).then(c => wrapFunctional(c.default || c)),
  OverseeAgentMobileInstallTimeFilter: () => import('../../components/oversee/agent/MobileInstallTimeFilter.vue' /* webpackChunkName: "components/oversee-agent-mobile-install-time-filter" */).then(c => wrapFunctional(c.default || c)),
  OverseeAgentNewPasswordModal: () => import('../../components/oversee/agent/NewPasswordModal.vue' /* webpackChunkName: "components/oversee-agent-new-password-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeAlarmDetailModal: () => import('../../components/oversee/alarm/AlarmDetailModal.vue' /* webpackChunkName: "components/oversee-alarm-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyAddWorkViewModal: () => import('../../components/oversee/approval/ApplyAddWorkViewModal.vue' /* webpackChunkName: "components/oversee-approval-apply-add-work-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyBusinessTripViewModal: () => import('../../components/oversee/approval/ApplyBusinessTripViewModal.vue' /* webpackChunkName: "components/oversee-approval-apply-business-trip-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyCommutModifyViewModal: () => import('../../components/oversee/approval/ApplyCommutModifyViewModal.vue' /* webpackChunkName: "components/oversee-approval-apply-commut-modify-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyHomeWorkViewModal: () => import('../../components/oversee/approval/ApplyHomeWorkViewModal.vue' /* webpackChunkName: "components/oversee-approval-apply-home-work-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyLeaveViewModal: () => import('../../components/oversee/approval/ApplyLeaveViewModal.vue' /* webpackChunkName: "components/oversee-approval-apply-leave-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyLocalViewModal: () => import('../../components/oversee/approval/ApplyLocalViewModal.vue' /* webpackChunkName: "components/oversee-approval-apply-local-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyScheduleChangeViewModal: () => import('../../components/oversee/approval/ApplyScheduleChangeViewModal.vue' /* webpackChunkName: "components/oversee-approval-apply-schedule-change-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyScheduleOptionModal: () => import('../../components/oversee/approval/ApplyScheduleOptionModal.vue' /* webpackChunkName: "components/oversee-approval-apply-schedule-option-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyScheduleViewDetailModal: () => import('../../components/oversee/approval/ApplyScheduleViewDetailModal.vue' /* webpackChunkName: "components/oversee-approval-apply-schedule-view-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyScheduleViewModal: () => import('../../components/oversee/approval/ApplyScheduleViewModal.vue' /* webpackChunkName: "components/oversee-approval-apply-schedule-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyStatusCancelItem: () => import('../../components/oversee/approval/ApplyStatusCancelItem.vue' /* webpackChunkName: "components/oversee-approval-apply-status-cancel-item" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalApplyStatusCancelViewModal: () => import('../../components/oversee/approval/ApplyStatusCancelViewModal.vue' /* webpackChunkName: "components/oversee-approval-apply-status-cancel-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalFileSetting: () => import('../../components/oversee/approval/ApprovalFileSetting.vue' /* webpackChunkName: "components/oversee-approval-file-setting" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalInfo: () => import('../../components/oversee/approval/ApprovalInfo.vue' /* webpackChunkName: "components/oversee-approval-info" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalInfoTextModal: () => import('../../components/oversee/approval/ApprovalInfoTextModal.vue' /* webpackChunkName: "components/oversee-approval-info-text-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalLineSetting: () => import('../../components/oversee/approval/ApprovalLineSetting.vue' /* webpackChunkName: "components/oversee-approval-line-setting" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalRefusalModal: () => import('../../components/oversee/approval/ApprovalRefusalModal.vue' /* webpackChunkName: "components/oversee-approval-refusal-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalCcLineModal: () => import('../../components/oversee/approval/CcLineModal.vue' /* webpackChunkName: "components/oversee-approval-cc-line-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalCommuteDescriptionModal: () => import('../../components/oversee/approval/CommuteDescriptionModal.vue' /* webpackChunkName: "components/oversee-approval-commute-description-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalLineModal: () => import('../../components/oversee/approval/LineModal.vue' /* webpackChunkName: "components/oversee-approval-line-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalLineSettingModal: () => import('../../components/oversee/approval/LineSettingModal.vue' /* webpackChunkName: "components/oversee-approval-line-setting-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalTableFilter: () => import('../../components/oversee/approval/TableFilter.vue' /* webpackChunkName: "components/oversee-approval-table-filter" */).then(c => wrapFunctional(c.default || c)),
  OverseeApprovalTableFilterFile: () => import('../../components/oversee/approval/TableFilterFile.vue' /* webpackChunkName: "components/oversee-approval-table-filter-file" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyOrganization: () => import('../../components/oversee/company/Organization.vue' /* webpackChunkName: "components/oversee-company-organization" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyOrganizationPositionModal: () => import('../../components/oversee/company/OrganizationPositionModal.vue' /* webpackChunkName: "components/oversee-company-organization-position-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyOrganizationUserModal: () => import('../../components/oversee/company/OrganizationUserModal.vue' /* webpackChunkName: "components/oversee-company-organization-user-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyOrganizationUserModalNoneFilter: () => import('../../components/oversee/company/OrganizationUserModalNoneFilter.vue' /* webpackChunkName: "components/oversee-company-organization-user-modal-none-filter" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyOrganizationUserModalSelectFilter: () => import('../../components/oversee/company/OrganizationUserModalSelectFilter.vue' /* webpackChunkName: "components/oversee-company-organization-user-modal-select-filter" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyPosition: () => import('../../components/oversee/company/Position.vue' /* webpackChunkName: "components/oversee-company-position" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyPositionModal: () => import('../../components/oversee/company/PositionModal.vue' /* webpackChunkName: "components/oversee-company-position-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyPrintCertificateModal: () => import('../../components/oversee/company/PrintCertificateModal.vue' /* webpackChunkName: "components/oversee-company-print-certificate-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyWorkPlaceInfo: () => import('../../components/oversee/company/WorkPlaceInfo.vue' /* webpackChunkName: "components/oversee-company-work-place-info" */).then(c => wrapFunctional(c.default || c)),
  OverseeCompanyWorkPlaceModal: () => import('../../components/oversee/company/WorkPlaceModal.vue' /* webpackChunkName: "components/oversee-company-work-place-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeDashboardAbsenceModal: () => import('../../components/oversee/dashboard/AbsenceModal.vue' /* webpackChunkName: "components/oversee-dashboard-absence-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeDashboardBusinessTripModal: () => import('../../components/oversee/dashboard/BusinessTripModal.vue' /* webpackChunkName: "components/oversee-dashboard-business-trip-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeDashboardEarlyModal: () => import('../../components/oversee/dashboard/EarlyModal.vue' /* webpackChunkName: "components/oversee-dashboard-early-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeDashboardLateModal: () => import('../../components/oversee/dashboard/LateModal.vue' /* webpackChunkName: "components/oversee-dashboard-late-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeDashboardLeaveModal: () => import('../../components/oversee/dashboard/LeaveModal.vue' /* webpackChunkName: "components/oversee-dashboard-leave-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeDashboardLocalCommuteInModal: () => import('../../components/oversee/dashboard/LocalCommuteInModal.vue' /* webpackChunkName: "components/oversee-dashboard-local-commute-in-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeDashboardLocalCommuteOutModal: () => import('../../components/oversee/dashboard/LocalCommuteOutModal.vue' /* webpackChunkName: "components/oversee-dashboard-local-commute-out-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeDashboardOvertimeModal: () => import('../../components/oversee/dashboard/OvertimeModal.vue' /* webpackChunkName: "components/oversee-dashboard-overtime-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeDashboardRemoteWorkModal: () => import('../../components/oversee/dashboard/RemoteWorkModal.vue' /* webpackChunkName: "components/oversee-dashboard-remote-work-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeEmployeeBasic: () => import('../../components/oversee/employee/EmployeeBasic.vue' /* webpackChunkName: "components/oversee-employee-basic" */).then(c => wrapFunctional(c.default || c)),
  OverseeEmployeeCompany: () => import('../../components/oversee/employee/EmployeeCompany.vue' /* webpackChunkName: "components/oversee-employee-company" */).then(c => wrapFunctional(c.default || c)),
  OverseeEmployeeCompanyItem: () => import('../../components/oversee/employee/EmployeeCompanyItem.vue' /* webpackChunkName: "components/oversee-employee-company-item" */).then(c => wrapFunctional(c.default || c)),
  OverseeEmployeeDefinitionModal: () => import('../../components/oversee/employee/EmployeeDefinitionModal.vue' /* webpackChunkName: "components/oversee-employee-definition-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeEmployeeExcelSubmitModal: () => import('../../components/oversee/employee/EmployeeExcelSubmitModal.vue' /* webpackChunkName: "components/oversee-employee-excel-submit-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeEmployeeModal: () => import('../../components/oversee/employee/EmployeeModal.vue' /* webpackChunkName: "components/oversee-employee-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeEmployeeTableFilter: () => import('../../components/oversee/employee/EmployeeTableFilter.vue' /* webpackChunkName: "components/oversee-employee-table-filter" */).then(c => wrapFunctional(c.default || c)),
  OverseeHelpViewModal: () => import('../../components/oversee/help/HelpViewModal.vue' /* webpackChunkName: "components/oversee-help-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveApprovalInfo: () => import('../../components/oversee/leave/ApprovalInfo.vue' /* webpackChunkName: "components/oversee-leave-approval-info" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveDocItem: () => import('../../components/oversee/leave/DocItem.vue' /* webpackChunkName: "components/oversee-leave-doc-item" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveDocViewModal: () => import('../../components/oversee/leave/DocViewModal.vue' /* webpackChunkName: "components/oversee-leave-doc-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveEditLeaveStatusModal: () => import('../../components/oversee/leave/EditLeaveStatusModal.vue' /* webpackChunkName: "components/oversee-leave-edit-leave-status-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveExcelSubmitModal: () => import('../../components/oversee/leave/ExcelSubmitModal.vue' /* webpackChunkName: "components/oversee-leave-excel-submit-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveLaborContentTextModal: () => import('../../components/oversee/leave/LaborContentTextModal.vue' /* webpackChunkName: "components/oversee-leave-labor-content-text-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveLaborDoc: () => import('../../components/oversee/leave/LaborDoc.vue' /* webpackChunkName: "components/oversee-leave-labor-doc" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveLaborPdfModal: () => import('../../components/oversee/leave/LaborPdfModal.vue' /* webpackChunkName: "components/oversee-leave-labor-pdf-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveLaborTitleTextModal: () => import('../../components/oversee/leave/LaborTitleTextModal.vue' /* webpackChunkName: "components/oversee-leave-labor-title-text-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveLaborViewModal: () => import('../../components/oversee/leave/LaborViewModal.vue' /* webpackChunkName: "components/oversee-leave-labor-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveAssignModal: () => import('../../components/oversee/leave/LeaveAssignModal.vue' /* webpackChunkName: "components/oversee-leave-assign-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveModal: () => import('../../components/oversee/leave/LeaveModal.vue' /* webpackChunkName: "components/oversee-leave-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeavePdfModal: () => import('../../components/oversee/leave/LeavePdfModal.vue' /* webpackChunkName: "components/oversee-leave-pdf-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveSetting: () => import('../../components/oversee/leave/LeaveSetting.vue' /* webpackChunkName: "components/oversee-leave-setting" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveSettingPanel: () => import('../../components/oversee/leave/LeaveSettingPanel.vue' /* webpackChunkName: "components/oversee-leave-setting-panel" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveStatus: () => import('../../components/oversee/leave/LeaveStatus.vue' /* webpackChunkName: "components/oversee-leave-status" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveStatusDetail: () => import('../../components/oversee/leave/LeaveStatusDetail.vue' /* webpackChunkName: "components/oversee-leave-status-detail" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveStatusDetailModal: () => import('../../components/oversee/leave/LeaveStatusDetailModal.vue' /* webpackChunkName: "components/oversee-leave-status-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveStatusMonthDetail: () => import('../../components/oversee/leave/LeaveStatusMonthDetail.vue' /* webpackChunkName: "components/oversee-leave-status-month-detail" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveLineSetting: () => import('../../components/oversee/leave/LineSetting.vue' /* webpackChunkName: "components/oversee-leave-line-setting" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveLineSettingModal: () => import('../../components/oversee/leave/LineSettingModal.vue' /* webpackChunkName: "components/oversee-leave-line-setting-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeavePlanContentTextModal: () => import('../../components/oversee/leave/PlanContentTextModal.vue' /* webpackChunkName: "components/oversee-leave-plan-content-text-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeavePlanDesignateTextModal: () => import('../../components/oversee/leave/PlanDesignateTextModal.vue' /* webpackChunkName: "components/oversee-leave-plan-designate-text-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeavePlanFooterTextModal: () => import('../../components/oversee/leave/PlanFooterTextModal.vue' /* webpackChunkName: "components/oversee-leave-plan-footer-text-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeavePlanTitleTextModal: () => import('../../components/oversee/leave/PlanTitleTextModal.vue' /* webpackChunkName: "components/oversee-leave-plan-title-text-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveRewardModal: () => import('../../components/oversee/leave/RewardModal.vue' /* webpackChunkName: "components/oversee-leave-reward-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveToggle: () => import('../../components/oversee/leave/Toggle.vue' /* webpackChunkName: "components/oversee-leave-toggle" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveUseSettingLabor: () => import('../../components/oversee/leave/UseSettingLabor.vue' /* webpackChunkName: "components/oversee-leave-use-setting-labor" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveUseSettingLeave: () => import('../../components/oversee/leave/UseSettingLeave.vue' /* webpackChunkName: "components/oversee-leave-use-setting-leave" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveUseStatusLabor: () => import('../../components/oversee/leave/UseStatusLabor.vue' /* webpackChunkName: "components/oversee-leave-use-status-labor" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveUseStatusLeave: () => import('../../components/oversee/leave/UseStatusLeave.vue' /* webpackChunkName: "components/oversee-leave-use-status-leave" */).then(c => wrapFunctional(c.default || c)),
  OverseeLeaveUsedLeaveModal: () => import('../../components/oversee/leave/UsedLeaveModal.vue' /* webpackChunkName: "components/oversee-leave-used-leave-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeNoticeModal: () => import('../../components/oversee/notice/NoticeModal.vue' /* webpackChunkName: "components/oversee-notice-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeNoticeViewModal: () => import('../../components/oversee/notice/NoticeViewModal.vue' /* webpackChunkName: "components/oversee-notice-view-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffAddLeftSeatModal: () => import('../../components/oversee/pcoff/AddLeftSeatModal.vue' /* webpackChunkName: "components/oversee-pcoff-add-left-seat-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffOfflineDefinitionModal: () => import('../../components/oversee/pcoff/OfflineDefinitionModal.vue' /* webpackChunkName: "components/oversee-pcoff-offline-definition-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffPcOffExceptionModal: () => import('../../components/oversee/pcoff/PcOffExceptionModal.vue' /* webpackChunkName: "components/oversee-pcoff-pc-off-exception-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffPcOffOffline: () => import('../../components/oversee/pcoff/PcOffOffline.vue' /* webpackChunkName: "components/oversee-pcoff-pc-off-offline" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffPcOffSchedule: () => import('../../components/oversee/pcoff/PcOffSchedule.vue' /* webpackChunkName: "components/oversee-pcoff-pc-off-schedule" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffPcOffScheduleAllowModal: () => import('../../components/oversee/pcoff/PcOffScheduleAllowModal.vue' /* webpackChunkName: "components/oversee-pcoff-pc-off-schedule-allow-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffPcOffSetting: () => import('../../components/oversee/pcoff/PcOffSetting.vue' /* webpackChunkName: "components/oversee-pcoff-pc-off-setting" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffPcOffStatus: () => import('../../components/oversee/pcoff/PcOffStatus.vue' /* webpackChunkName: "components/oversee-pcoff-pc-off-status" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffPcOffStatusDetailModal: () => import('../../components/oversee/pcoff/PcOffStatusDetailModal.vue' /* webpackChunkName: "components/oversee-pcoff-pc-off-status-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffPcOffStatusDetailModalTab: () => import('../../components/oversee/pcoff/PcOffStatusDetailModalTab.vue' /* webpackChunkName: "components/oversee-pcoff-pc-off-status-detail-modal-tab" */).then(c => wrapFunctional(c.default || c)),
  OverseePcoffPcOffUse: () => import('../../components/oversee/pcoff/PcOffUse.vue' /* webpackChunkName: "components/oversee-pcoff-pc-off-use" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesAdditionExceptionModal: () => import('../../components/oversee/policies/AdditionExceptionModal.vue' /* webpackChunkName: "components/oversee-policies-addition-exception-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesAdditionalAssign: () => import('../../components/oversee/policies/AdditionalAssign.vue' /* webpackChunkName: "components/oversee-policies-additional-assign" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesAdditionalAssignModal: () => import('../../components/oversee/policies/AdditionalAssignModal.vue' /* webpackChunkName: "components/oversee-policies-additional-assign-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesAdditionalSetting: () => import('../../components/oversee/policies/AdditionalSetting.vue' /* webpackChunkName: "components/oversee-policies-additional-setting" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesDaySchedule: () => import('../../components/oversee/policies/DaySchedule.vue' /* webpackChunkName: "components/oversee-policies-day-schedule" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesDeemedSetting: () => import('../../components/oversee/policies/DeemedSetting.vue' /* webpackChunkName: "components/oversee-policies-deemed-setting" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesMonthDetailModal: () => import('../../components/oversee/policies/MonthDetailModal.vue' /* webpackChunkName: "components/oversee-policies-month-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesMonthSchedule: () => import('../../components/oversee/policies/MonthSchedule.vue' /* webpackChunkName: "components/oversee-policies-month-schedule" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesDetailModal: () => import('../../components/oversee/policies/PoliciesDetailModal.vue' /* webpackChunkName: "components/oversee-policies-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesModal: () => import('../../components/oversee/policies/PoliciesModal.vue' /* webpackChunkName: "components/oversee-policies-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesW001: () => import('../../components/oversee/policies/W001.vue' /* webpackChunkName: "components/oversee-policies-w001" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesW002: () => import('../../components/oversee/policies/W002.vue' /* webpackChunkName: "components/oversee-policies-w002" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesW003: () => import('../../components/oversee/policies/W003.vue' /* webpackChunkName: "components/oversee-policies-w003" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesW004: () => import('../../components/oversee/policies/W004.vue' /* webpackChunkName: "components/oversee-policies-w004" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesW005: () => import('../../components/oversee/policies/W005.vue' /* webpackChunkName: "components/oversee-policies-w005" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesW006: () => import('../../components/oversee/policies/W006.vue' /* webpackChunkName: "components/oversee-policies-w006" */).then(c => wrapFunctional(c.default || c)),
  OverseePoliciesWeekSchedule: () => import('../../components/oversee/policies/WeekSchedule.vue' /* webpackChunkName: "components/oversee-policies-week-schedule" */).then(c => wrapFunctional(c.default || c)),
  OverseeRegisterAuthCode: () => import('../../components/oversee/register/AuthCode.vue' /* webpackChunkName: "components/oversee-register-auth-code" */).then(c => wrapFunctional(c.default || c)),
  OverseeRegisterCompanyId: () => import('../../components/oversee/register/CompanyId.vue' /* webpackChunkName: "components/oversee-register-company-id" */).then(c => wrapFunctional(c.default || c)),
  OverseeRegisterCompanyInfo: () => import('../../components/oversee/register/CompanyInfo.vue' /* webpackChunkName: "components/oversee-register-company-info" */).then(c => wrapFunctional(c.default || c)),
  OverseeRegisterCompanySize: () => import('../../components/oversee/register/CompanySize.vue' /* webpackChunkName: "components/oversee-register-company-size" */).then(c => wrapFunctional(c.default || c)),
  OverseeRegisterEmail: () => import('../../components/oversee/register/Email.vue' /* webpackChunkName: "components/oversee-register-email" */).then(c => wrapFunctional(c.default || c)),
  OverseeRegisterPassword: () => import('../../components/oversee/register/Password.vue' /* webpackChunkName: "components/oversee-register-password" */).then(c => wrapFunctional(c.default || c)),
  OverseeScheduleDetailModal: () => import('../../components/oversee/schedule/ScheduleDetailModal.vue' /* webpackChunkName: "components/oversee-schedule-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserAcr: () => import('../../components/oversee/user/Acr.vue' /* webpackChunkName: "components/oversee-user-acr" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserAdministratorModal: () => import('../../components/oversee/user/AdministratorModal.vue' /* webpackChunkName: "components/oversee-user-administrator-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserAlarm: () => import('../../components/oversee/user/Alarm.vue' /* webpackChunkName: "components/oversee-user-alarm" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserAuthority: () => import('../../components/oversee/user/Authority.vue' /* webpackChunkName: "components/oversee-user-authority" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserLog: () => import('../../components/oversee/user/Log.vue' /* webpackChunkName: "components/oversee-user-log" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserPasswordChangeModal: () => import('../../components/oversee/user/PasswordChangeModal.vue' /* webpackChunkName: "components/oversee-user-password-change-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserProfile: () => import('../../components/oversee/user/Profile.vue' /* webpackChunkName: "components/oversee-user-profile" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserProfileGroup: () => import('../../components/oversee/user/ProfileGroup.vue' /* webpackChunkName: "components/oversee-user-profile-group" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserSMTP: () => import('../../components/oversee/user/SMTP.vue' /* webpackChunkName: "components/oversee-user-s-m-t-p" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserSecurity: () => import('../../components/oversee/user/Security.vue' /* webpackChunkName: "components/oversee-user-security" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserTableFilterAdminLog: () => import('../../components/oversee/user/TableFilterAdminLog.vue' /* webpackChunkName: "components/oversee-user-table-filter-admin-log" */).then(c => wrapFunctional(c.default || c)),
  OverseeUserTableFilterAuthority: () => import('../../components/oversee/user/TableFilterAuthority.vue' /* webpackChunkName: "components/oversee-user-table-filter-authority" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeCommute: () => import('../../components/oversee/workingattitude/Commute.vue' /* webpackChunkName: "components/oversee-workingattitude-commute" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeCommuteIp: () => import('../../components/oversee/workingattitude/CommuteIp.vue' /* webpackChunkName: "components/oversee-workingattitude-commute-ip" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeCommuteIpModal: () => import('../../components/oversee/workingattitude/CommuteIpModal.vue' /* webpackChunkName: "components/oversee-workingattitude-commute-ip-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeDesignHolidayModal: () => import('../../components/oversee/workingattitude/DesignHolidayModal.vue' /* webpackChunkName: "components/oversee-workingattitude-design-holiday-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeHoliday: () => import('../../components/oversee/workingattitude/Holiday.vue' /* webpackChunkName: "components/oversee-workingattitude-holiday" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeHolidayModal: () => import('../../components/oversee/workingattitude/HolidayModal.vue' /* webpackChunkName: "components/oversee-workingattitude-holiday-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeStatus: () => import('../../components/oversee/workingattitude/Status.vue' /* webpackChunkName: "components/oversee-workingattitude-status" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeStatusDetailModal: () => import('../../components/oversee/workingattitude/StatusDetailModal.vue' /* webpackChunkName: "components/oversee-workingattitude-status-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeStatusDetailModalTable: () => import('../../components/oversee/workingattitude/StatusDetailModalTable.vue' /* webpackChunkName: "components/oversee-workingattitude-status-detail-modal-table" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingattitudeStatusEditModal: () => import('../../components/oversee/workingattitude/StatusEditModal.vue' /* webpackChunkName: "components/oversee-workingattitude-status-edit-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingtimeDownloadModal: () => import('../../components/oversee/workingtime/DownloadModal.vue' /* webpackChunkName: "components/oversee-workingtime-download-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingtimeWorkingTimeCommute: () => import('../../components/oversee/workingtime/WorkingTimeCommute.vue' /* webpackChunkName: "components/oversee-workingtime-working-time-commute" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingtimeWorkingTimeDefinitionModal: () => import('../../components/oversee/workingtime/WorkingTimeDefinitionModal.vue' /* webpackChunkName: "components/oversee-workingtime-working-time-definition-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingtimeWorkingTimeDetailModal: () => import('../../components/oversee/workingtime/WorkingTimeDetailModal.vue' /* webpackChunkName: "components/oversee-workingtime-working-time-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  OverseeWorkingtimeWorkingTimeStatus: () => import('../../components/oversee/workingtime/WorkingTimeStatus.vue' /* webpackChunkName: "components/oversee-workingtime-working-time-status" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
