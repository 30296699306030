import Vue from 'vue'

Vue.mixin({
  methods: {
    editAction() {
      window.$(`#${this.tableId}`).on('click', 'button.edit', event => {
        const closestRow = window.$(event.currentTarget).parents('tr')
        const row = this.dataTable.row(closestRow).data()

        this.formData = window._.cloneDeep(row)
        this.formData.mod = true

        window.$(`#${this.$refs.modal.modal_id}`).modal({
          backdrop: 'static'
        })
      })
    },
    profile(data, type, row) {
      let departmnet_name = ''
      let path = ''
      let rank = ''
      let status = ''

      if (row.departments.length !== 0) {
        const department_info = window._.find(row.departments, {
          default: true
        })

        if (department_info !== undefined) {
          departmnet_name = department_info.name
          path = department_info.path
          rank = department_info.rank
        } else {
          departmnet_name = row.departments[0].name
          path = row.departments[0].path
          rank = row.departments[0].rank
        }
      }

      // 오더링을 위한 Dummy Data
      let field = `<div class="d-flex align-items-center"><span class="d-none">${row.name}</span>`

      if (row.type === 'pregnancy') {
        status =
          '<em class="font-icon icon-user_heart_solid color-danger-light" title="임산부"></em>'
      } else if (row.status === 'absence') {
        status =
          '<em class="font-icon icon-time font-secondary2" title="휴직중"></em>'
      } else if (row.status === 'parental') {
        status =
          '<em class="font-icon icon-time color-danger-light" title="육아휴직중"></em>'
      } else if (row.status === 'resignation') {
        status =
          '<em class="font-icon icon-user_minus_solid font-secondary2" title="퇴사자"></em>'
      }

      if (row.image !== null && row.image !== undefined) {
        field += `<div class="profile"><div class="profile-img"><img width="40" src="${row.image.url}"></div></div>`
      } else {
        field += `<div class="profile"><div class="profile-img"><span>${row.name}</span></div></div>`
      }

      field += `<div class="user-info"><div title="${row.name}">${
        type === 'export' ? row.name : this.subStr(row.name, 20)
      } ${rank} (${row.email})${status}</div>`
      field += `<div class="font-secondary"><span class="small" title="${path}">${
        type === 'export' ? departmnet_name : this.subStr(departmnet_name, 35)
      }</span></div></div></div>`

      return field
    },
    profileSimple(data, type, row) {
      let name = ''
      let email = ''
      let image = ''
      let departmnet_name = ''
      let path = ''
      let rank = ''

      if (row.department !== null) {
        path = row.department.path
        rank = row.department.rank
      }

      if (row.user !== undefined) {
        name = row.user.name
        email = row.user.email

        const department_info = window._.find(row.user.departments, {
          default: true
        })

        if (department_info !== undefined) {
          departmnet_name = department_info.name
          path = department_info.path
          rank = department_info.rank
        } else {
          departmnet_name = row.user.departments[0].name
          path = row.user.departments[0].path
          rank = row.user.departments[0].rank
        }

        if (row.user.image !== null && row.user.image !== undefined) {
          image = row.user.image.url
        }
      } else {
        name = row.name
        email = row.email

        if (row.image !== null && row.image !== undefined) {
          image = row.image.url
        }
      }

      // 오더링을 위한 Dummy Data
      let field = `<div class="d-flex align-items-center"><span class="d-none">${name}</span>`

      if (image !== '') {
        field += `<div class="profile"><div class="profile-img"><img src="${image}"></div></div>`
      } else {
        field += `<div class="profile"><div class="profile-img"><span>${name}</span></div></div>`
      }

      field += `<div class="user-info"><div title="${name}">${this.subStr(
        name,
        20
      )} ${rank} (${email})</div>`
      field += `<div class="font-secondary"><span class="small" title="${path}">${this.subStr(
        departmnet_name,
        35
      )}</span></div></div></div>`

      return field
    },
    profileDashboard(data, type, row) {
      let rank = ''
      let status = ''

      if (row.departments.length !== 0) {
        const department_info = window._.find(row.departments, {
          default: true
        })

        if (department_info !== undefined) {
          rank = department_info.rank
        } else {
          rank = row.departments[0].rank
        }
      }

      // 오더링을 위한 Dummy Data
      let field = `<div class="d-flex align-items-center"><span class="d-none">${row.name}</span>`

      if (row.type === 'pregnancy') {
        status =
          '<em class="font-icon icon-user_heart_solid color-danger-light" title="임산부"></em>'
      } else if (row.status === 'absence') {
        status =
          '<em class="font-icon icon-time font-secondary2" title="휴직중"></em>'
      } else if (row.status === 'parental') {
        status =
          '<em class="font-icon icon-time color-danger-light" title="육아휴직중"></em>'
      } else if (row.status === 'resignation') {
        status =
          '<em class="font-icon icon-user_minus_solid font-secondary2" title="퇴사자"></em>'
      }

      if (row.image !== null && row.image !== undefined) {
        field += `<div class="profile"><div class="profile-img"><img width="40" src="${row.image.url}"></div></div>`
      } else {
        field += `<div class="profile"><div class="profile-img"><span>${row.name}</span></div></div>`
      }

      field += `<div class="user-info"><div title="${row.name}">${
        type === 'export' ? row.name : this.subStr(row.name, 20)
      } ${rank} (${row.email})${status}</div></div></div>`

      return field
    },
    exportField(data, row, target) {
      if (data !== null) {
        return row === target
          ? data
              .replace(/<span class="d-none">(.+?)<\/span>/gi, '') // datatable ordering data remove
              .replace(/<span>(.+?)<\/span>/gi, '') // first name remove
              .replace(/title="(.+?)"/gi, '') // title remove (부서 > 로 인해 정규식 동작을 위해 처리)
              .replace(/\)/, ')\n') // br 개행처리
              .replace(/<\/?[^>]+(>|$)/g, '') // html remove
          : isNaN(data)
          ? data.replace(/<\/?[^>]+(>|$)/g, '')
          : data
      }
    },
    subStr(string, length) {
      string =
        string !== null &&
        string !== undefined &&
        string.length > window._.parseInt(length)
          ? string.substr(0, length) + '…'
          : string

      return string
    }
  }
})
