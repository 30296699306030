import Vue from 'vue'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

const releaseInfo = window.__NUXT__.config.releaseInfo

Raven.config(
  'https://942c5d9e7ae84f1ca579e86b24f4846d@sentry.jiransoft.co.kr/54',
  {
    release: releaseInfo
  }
)
  .addPlugin(RavenVue, Vue)
  .install()

Sentry.init({
  dsn: 'https://942c5d9e7ae84f1ca579e86b24f4846d@sentry.jiransoft.co.kr/54',
  release: releaseInfo,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })]
})
