export const strict = false
import createPersistedState from 'vuex-persistedstate'
window._ = require('lodash')

export const state = () => ({
  checkInterval: null,
  logoutModal: false,
  logoutMessage: '',
  company: null,
  places: null,
  companies: [],
  companyCache: null,
  employmentTypes: [],
  organization: [],
  departments: [],
  employees: [],
  ranks: [],
  roles: [],
  holidays: [],
  leaveTypes: [],
  loadCheck: false
})

export const mutations = {
  setCompany(state, value) {
    state.company = value
  },
  setPlaces(state, value) {
    state.places = value
  },
  logoutModal(state, status) {
    state.logoutModal = status
  },
  logoutMessage(state, text) {
    state.logoutMessage = text
  },
  companyCache(state, text) {
    state.companyCache = text
  },
  setEmploymentTypes(state, value) {
    state.employmentTypes = value
  },
  setDepartments(state, value) {
    state.departments = value
  },
  setEmployees(state, value) {
    state.employees = value
  },
  setRanks(state, value) {
    state.ranks = value
  },
  setRoles(state, value) {
    state.roles = value
  },
  setHolidays(state, value) {
    state.holidays = value
  },
  setLeaveTypes(state, value) {
    state.leaveTypes = value
  },
  setLoadCheck(state, value) {
    state.loadCheck = value
  }
}

export const actions = {
  logout: window._.debounce(async function({ state }) {
    await window.localStorage.removeItem('vuex')
    await this.$auth.logout()

    window.$('.swal2-cancel').click()
    window.$('.modal').modal('hide')

    if (state.logoutModal === false) {
      this.$router.go({ path: '/login' })
    }
  }, 1000),

  getCompany({ state, commit }) {
    if (this.$auth.loggedIn) {
      commit('setCompany', state.auth.user.user.company_id)
    }
  },

  async getPlaces({ state, commit }) {
    await this.$axios.get(`/companies/${state.company}`).then(res => {
      commit('setPlaces', res.data.places)
    })
  },

  async getEmploymentTypes({ state, commit }) {
    await this.$axios
      .get(`/companies/${state.company}/employment-types`)
      .then(res => {
        commit('setEmploymentTypes', res.data.data)
      })
  },

  async getDepartments({ state, commit }) {
    await this.$axios
      .get(`/companies/${state.company}/departments`)
      .then(res => {
        let departments = res.data.data.filter(
          row => row['accessible'] === true
        )

        if (!/Company/.test(state.auth.user.manager.managerable_type)) {
          let department = window._.find(departments, {
            id: state.auth.user.manager.managerable_id
          })

          department.parent_id = '#'
          department.position = 0
        }

        commit('setDepartments', departments)
      })
  },

  async getEmployees({ state, commit }) {
    await this.$axios
      .get(
        `/companies/${state.company}/employees?only=id,company_id,name,email,type,status,departments`
      )
      .then(res => {
        let employees = res.data.data.filter(row => row['accessible'] === true)

        const ids = window._.map(state.departments, 'id')

        let userInfo = []

        window._.forEach(employees, data => {
          const check = data.departments.filter(
            row => ids.filter(id => id === row['id']).length !== 0
          )

          data.departments = check

          if (check.length !== 0) {
            userInfo.push(data)
          }
        })

        commit('setEmployees', userInfo)
      })
  },

  async getRanks({ state, commit }) {
    await this.$axios.get(`/companies/${state.company}/ranks`).then(res => {
      commit('setRanks', res.data.data)
    })
  },

  async getRoles({ state, commit }) {
    await this.$axios.get(`/companies/${state.company}/roles`).then(res => {
      commit('setRoles', res.data.data)
    })
  },

  getHolidays: window._.debounce(async function({ commit }, year) {
    await this.$axios.get(`/search/holidays?year=${year}`).then(res => {
      commit('setHolidays', res.data)
    })
  }, 350),

  getRangeHolidays: window._.debounce(async function({ commit }, year) {
    await this.$axios
      .get(`/search/holidays?start_date=${year.start}&end_date=${year.end}`)
      .then(res => {
        commit('setHolidays', res.data)
      })
  }, 350),

  async getLeaveTypes({ state, commit }, id) {
    await this.$axios
      .get(`/companies/${state.company}/employees/${id}/leave-types`)
      .then(res => {
        commit('setLeaveTypes', res.data.data)
      })
  },

  async getLoadCheck({ commit }, value) {
    commit('setLoadCheck', value)
  }
}

export const plugins = [
  createPersistedState({
    key: 'vuex',
    reducer(val) {
      if (val.auth.loggedIn === false) {
        return {}
      }
      return val
    },
    storage: window.localStorage
  })
]
